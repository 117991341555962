import axios from 'Utils/axios';
import store from 'redux/store';
import api from 'api';
import { getCurrentUserId } from 'Utils/loginUtils';

export const getAccountInfo = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.getAccountInformation}`)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);

                        store.dispatch({
                            type: 'STORE_USER',
                            payload: {
                                user: res.data.payload[0],
                            },
                        });
                    } else {
                        reject(res.error);
                    }
                }
            })
            .catch((err) => {
                if (err?.response?.data?.error) {
                    reject(err.response.data.error);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const updateInformation = (id, firstName, lastName, activeMfa, profilePic, organization) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.updateUserAccount}${id}`,

                {
                    firstName: firstName,
                    lastName: lastName,
                    activeMfa: activeMfa,
                    profilePic: profilePic,
                    organization: organization,
                },
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject(res.error);
                    }
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const addEmailConfiguration = (credentials) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.addEmailConfiguration}`,
                credentials,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else if (res.data.code === 409) {
                        reject(res.error);
                        store.dispatch({
                            type: 'UPDATE_SNACK',
                            payload: {
                                snackbar: true,
                                message: res.data.error,
                                type: 'error',
                            },
                        });
                    } else if (res.data.code === 500) {
                        reject(res.error);
                        store.dispatch({
                            type: 'UPDATE_SNACK',
                            payload: {
                                snackbar: true,
                                message: 'Invalid credentials for email configuration .',
                                type: 'error',
                            },
                        });
                    } else {
                        reject(res.error);
                        store.dispatch({
                            type: 'UPDATE_SNACK',
                            payload: {
                                snackbar: true,
                                message: 'Error in email configuration .',
                                type: 'error',
                            },
                        });
                    }
                } else {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Error in email configuration .',
                            type: 'error',
                        },
                    });
                }
            })
            .catch((err) => {
                if (err?.response?.data?.error) {
                    reject(err.response.data.error);

                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const updateEmailConfiguration = (credentials) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.updateEmailConfiguration}`,
                credentials,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else if (res.data.code === 409) {
                        reject(res.error);
                        store.dispatch({
                            type: 'UPDATE_SNACK',
                            payload: {
                                snackbar: true,
                                message: res.data.error,
                                type: 'error',
                            },
                        });
                    } else if (res.data.code === 500) {
                        reject(res.error);
                        store.dispatch({
                            type: 'UPDATE_SNACK',
                            payload: {
                                snackbar: true,
                                message: 'Invalid credentials for email configuration .',
                                type: 'error',
                            },
                        });
                    } else {
                        reject(res.error);
                        store.dispatch({
                            type: 'UPDATE_SNACK',
                            payload: {
                                snackbar: true,
                                message: 'Error in email configuration .',
                                type: 'error',
                            },
                        });
                    }
                } else {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Error in email configuration .',
                            type: 'error',
                        },
                    });
                }
            })
            .catch((err) => {
                reject(err.response.data.error);

                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const deleteEmailConfiguration = (credentials) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.deleteEmailConfiguration}`,
                credentials,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else if (res.data.code === 409) {
                        reject(res.error);
                        store.dispatch({
                            type: 'UPDATE_SNACK',
                            payload: {
                                snackbar: true,
                                message: res.data.error,
                                type: 'error',
                            },
                        });
                    } else if (res.data.code === 500) {
                        reject(res.error);
                        store.dispatch({
                            type: 'UPDATE_SNACK',
                            payload: {
                                snackbar: true,
                                message: 'Invalid credentials for email configuration .',
                                type: 'error',
                            },
                        });
                    } else {
                        reject(res.error);
                        store.dispatch({
                            type: 'UPDATE_SNACK',
                            payload: {
                                snackbar: true,
                                message: 'Error in email configuration .',
                                type: 'error',
                            },
                        });
                    }
                } else {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Error in email configuration .',
                            type: 'error',
                        },
                    });
                }
            })
            .catch((err) => {
                reject(err.response.data.error);

                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const getEmailConfigurationFn = (credentials) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.getEmailConfiguration}`,
                credentials,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        store.dispatch({
                            type: 'STORE_EMAIL_CONFIG',
                            payload: {
                                emailConfig: res.data.payload,
                            },
                        });
                        resolve(res.data.payload);
                    }
                }
            })
            .catch((err) => {
                reject(err.response.data.error);

                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

// update password
export const updatePassword = (currentpassword, newpassword, username) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.updatePass}`,

                {
                    oldPass: currentpassword,
                    newPass: newpassword,
                },
            )
            .then((res) => {
                if (res.data.code === 200) {
                    resolve(res);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Password update successfully.',
                            // message: err.message,
                            type: 'success',
                        },
                    });
                    store.dispatch({
                        type: 'START_LOADING',
                        payload: {
                            loading: false,
                        },
                    });
                } else if (res.data.code === 401) {
                    reject(res.data.error);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Password not matched.',
                            type: 'error',
                        },
                    });
                    store.dispatch({
                        type: 'START_LOADING',
                        payload: {
                            loading: false,
                        },
                    });
                } else {
                    reject(res.data.error);
                    store.dispatch({
                        type: 'START_LOADING',
                        payload: {
                            loading: false,
                        },
                    });
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
                store.dispatch({
                    type: 'START_LOADING',
                    payload: {
                        loading: false,
                    },
                });
            });
    });
};

export const getUserOrganizations = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${
                    process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST +
                    api.getUserOrganizations.replace('{userId}', getCurrentUserId())
                }`,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject(res.error);
                    }
                }
            })
            .catch((err) => {
                if (err?.response?.data?.error) {
                    reject(err.response.data.error);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                }
            });
    });
};
