const INIT_STATE = {
    open: true, //for draw
    width: 248,
};

const LeftDrawer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'LEFT_DRAWER':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default LeftDrawer;
