import axios from 'Utils/axios';
import store from 'redux/store';
import api from 'api';
import { signIn } from '../../authentication/services/userservice';
import { logout } from 'Utils/loginUtils';
import { fetchWorkspace } from './createForm.action';
import { Cookies } from 'react-cookie';
import * as constants from 'Utils/domainSuffix';

const cookies = new Cookies();
export const ssologin = (credentials) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.ssologin}`, credentials)
            .then((res) => {
                if (res.data.code === 200) {
                    axios.defaults.headers.common['Authorization'] =
                        res.data.payload.userId + ' ' + res.data.payload.token;
                    localStorage.setItem('token', res.data.payload.userId + ' ' + res.data.payload.token);
                    fetchWorkspace().then((res) => {
                        resolve(true);
                    });
                }
            })
            .catch((error) => {
                reject(error.response.data.code);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: error.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const loginauth = (username, password) => {
    return new Promise((resolve, reject) => {
        signIn(username, password, true)
            .then((result) => {
                if (result.type === 'success') {
                    resolve(result);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const signup = (firstName, lastName, email, password) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.usersignup}`,

                {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    password: password,
                },
            )
            .then((res) => {
                resolve(res.data);
                if (res.status === 201) {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'User Created Successfully',
                            type: 'success',
                        },
                    });
                } else if (res.data.code === 401) {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: res.data.message,
                            type: 'success',
                        },
                    });
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const forgetPassSendCodeFn = (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.forgetPassSendCode}`,

                {
                    email: data,
                },
            )
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const getFormsUserToken = () => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.userlogin}`, {})
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload.userId + ' ' + res.data.payload.token);
                    } else {
                        reject(res.error);
                    }
                }
            })
            .catch((err) => {
                reject(err?.response?.data?.error);

                if (err?.response?.data?.code === 402) {
                    localStorage.setItem('email', err?.response?.data?.payload?.email);
                } else if (err?.response?.data?.code === 410) {
                    localStorage.setItem('email', err?.response?.data?.payload?.email);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Your plan subscription has been expired please renew your subscription.',
                            type: 'error',
                        },
                    });
                } else if (err?.response?.data?.code === 500 || err?.response?.data?.code === 422) {
                    cookies.remove('token', { domain: constants.CURRENT_DOMAIN_SUFFIX });
                    logout();
                }
            });
    });
};
