import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'Utils/axios';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { getsessionplanFn, GetPaymentSessionFn, GetPaymentUpgradeSessionFn } from 'redux/action/Payment.action';
import store from 'redux/store';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { fetchWorkspace } from 'redux/action/createForm.action';
import FreePlan from '../components/Payment/FreePlan';
import IndividualPlan from 'components/Payment/IndividualPlan';
import ProfessionalPlan from 'components/Payment/ProfessionalPlan';
import TeamPlan from 'components/Payment/TeamPlan';
import EnterprisePlan from 'components/Payment/EnterprisePlan';
import PackagesSection from 'components/Payment/PackagesSection';
const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(4, 0, 3),
    },
    heroContent1: {
        height: '90vh',
        overflowX: 'hidden',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: 0,
        },
    },
    headingtypo: {
        textTransform: 'none',
        fontSize: '2rem',
    },
    dashicon: {
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    dashtypo: {
        paddingLeft: 5,
        paddingTop: 2,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        color: theme.palette.primary.main,
    },
    homeicon: {
        height: 24,
        width: 24,
        color: theme.palette.primary.main,
    },
    maindiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
    },
    cardPricing1: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: theme.spacing(3),
    },
    Cardclass: {
        borderRadius: 5,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '250px 530px',
        width: '250px',
        height: '530px',
        boxShadow:
            'inset 0px 3px 2px 1px rgb(18 17 17 / 84%), 0px 1px 1px 0px rgb(18 18 18 / 79%),inset -1px -7px 0px 0px rgb(34 32 32 / 3%)',
    },
    cardHeader: {
        color: '#FFFFFF',
        textTransform: 'capitalize',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
    },
    divider: {
        height: 2,
        background: '#f0f0f0',
        margin: 20,
    },
    btndiv: {
        padding: 16,
    },
    btnclass: {
        '&.MuiButton-contained.Mui-disabled': {
            boxShadow: 'none',
            backgroundColor: theme.palette.secondary.main,
            color: '#FFFFFF',
        },
    },

    linktypo: {
        fontSize: 16,
        fontWeight: 'bold',
        cursor: 'pointer',
        animation: 'pulse',
        animationDuration: '1s',
        animationIterationCount: '5',
        direction: 'alternate',
        transition: 'ease-in',
        transitionDuration: '1s',
        '&:disabled': {
            color: 'gray',
            textDecoration: 'none',
        },
    },
    togglebtn: {
        '&.MuiToggleButton-root': {
            color: '#000000',
            border: 'none',
            background: '#FFFFFF',
            width: '10rem',
            fontSize: 16,
            padding: '8px',
            textTransform: 'capitalize',
        },
        '&.MuiToggleButton-root.Mui-selected': {
            color: '#000000',
            background: '#19C787',
        },
    },
    autocompleteclass: {
        padding: 16,
    },
    autoclass: {
        '& .MuiFormControl-root': {
            height: '45px',
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
            padding: 0,
        },
    },
    contentbox: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: '250px 175px',
        width: '250px',
        height: '175px',
    },
    contentbox1: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: '250px 265px',
        width: '250px',
        height: '265px',
    },
    numericInput: {
        width: '6rem',
        paddingTop: 4,
        paddingRight: 0,
        paddingLeft: 0,
        paddingBottom: 4,
        textAlign: 'center',
        background: '#FAF9FD',
        border: 'none',
    },
    seatsection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        backgroundRepeat: 'no-repeat',
        backgroundSize: '250px 90px',
        width: '250px',
        height: '90px',
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    flexcontainerwrap: {
        padding: 0,
        margin: 0,
        listStyle: 'none',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const PaymentPackage = (props) => {
    const classes = useStyles();
    const [allData, setAllData] = useState();
    const [btnindex, setbtnindex] = useState();
    const [packduration, setpackduration] = React.useState('yearly');
    let history = useHistory();

    useEffect(() => {
        store.dispatch({
            type: 'START_LOADING',
            payload: {
                loading: true,
            },
        });
        getsessionplanFn()
            .then((data) => {
                store.dispatch({
                    type: 'START_LOADING',
                    payload: {
                        loading: false,
                    },
                });
                if (data.length > 0) {
                    setAllData(data);
                } else {
                    setAllData();
                }
            })
            .catch((err) => {
                store.dispatch({
                    type: 'START_LOADING',
                    payload: {
                        loading: false,
                    },
                });
            });
    }, []);
    const paymentFn = (subid, subsType, noOfseats) => {
        props.setloadingBtn1(true);
        store.dispatch({
            type: 'START_LOADING',
            payload: {
                loading: true,
            },
        });
        GetPaymentSessionFn(subid, subsType, noOfseats)
            .then(async (res) => {
                if (res.code === 200) {
                    window.location.replace(res.payload.url);
                    store.dispatch({
                        type: 'START_LOADING',
                        payload: {
                            loading: false,
                        },
                    });
                } else if (res.code === 201) {
                    localStorage.removeItem('email');

                    axios.defaults.headers.common['Authorization'] = res.payload.userId + ' ' + res.payload.token;
                    localStorage.setItem('token', res.payload.userId + ' ' + res.payload.token);

                    await fetchWorkspace();

                    props.setloadingBtn1(false);
                    window.location.replace('/');
                }
            })
            .catch((err) => {
                props.setloadingBtn1(false);
                store.dispatch({
                    type: 'START_LOADING',
                    payload: {
                        loading: false,
                    },
                });
            });
    };
    const UpgradeFn = (subid, subsType, noOfseats) => {
        props.setloadingBtn1(true);
        store.dispatch({
            type: 'START_LOADING',
            payload: {
                loading: true,
            },
        });
        GetPaymentUpgradeSessionFn(subid, subsType, noOfseats)
            .then((res) => {
                if (res.code === 200) {
                    window.location.replace(res.payload.url);
                } else if (res.code === 201) {
                    window.location.reload();
                    props.setloadingBtn1(false);
                    store.dispatch({
                        type: 'START_LOADING',
                        payload: {
                            loading: false,
                        },
                    });
                }
            })
            .catch((err) => {
                store.dispatch({
                    type: 'START_LOADING',
                    payload: {
                        loading: false,
                    },
                });
                props.setloadingBtn1(false);
            });
    };
    const handlePackage = (event, duration) => {
        if (duration !== null) {
            setpackduration(duration);
        }
    };

    useEffect(() => {
        if (props.subscriptionPlan === 'free forever') {
            setbtnindex(0);
        }
        if (props.subscriptionPlan === 'individual') {
            setbtnindex(1);
        }
        if (props.subscriptionPlan === 'professional') {
            setbtnindex(2);
        }
        if (props.subscriptionPlan === 'team') {
            setbtnindex(3);
        }
        if (props.subscriptionPlan === 'enterprise') {
            setbtnindex(4);
        }
    }, [allData]);

    useEffect(() => {
        if (history.location.pathname.includes('/subscription-plans')) {
            setpackduration('yearly');
        } else {
            if (props.subsType === 'monthly') {
                setpackduration('monthly');
            } else if (props.subsType === 'yearly') {
                setpackduration('yearly');
            } else {
                setpackduration('yearly');
            }
        }
    }, []);

    const redirectFn = () => {
        window.open('https://forms.intely.io/myform/625d33f817ec0d39b2247619');
    };
    return (
        <div className={classes.heroContent1}>
            {history.location.pathname.includes('/subscription-plans') ? (
                <div className={classes.root}>
                    <Button
                        style={{ textTransform: 'none' }}
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<ArrowBackIcon />}
                        onClick={() =>
                            window.location.replace(process.env.REACT_APP_INTELY_PLATFORM_EXTERNAL_HOST + '/dashboard')
                        }
                    >
                        Back to intelyPlatform
                    </Button>
                </div>
            ) : null}
            <CssBaseline />

            <Container component="main" className={classes.heroContent}>
                <Box align="center" className={classes.headingtypo}>
                    {history.location.pathname.includes('/subscription-plans') ? (
                        <Box component="span">Get started with a Free account & upgrade as you grow.</Box>
                    ) : (
                        <Box component="span">
                            {packduration === 'monthly'
                                ? ' Monthly intelyForms Pricing'
                                : ' Yearly intelyForms Pricing'}
                        </Box>
                    )}
                </Box>
                <Box align="center" className={classes.headingtypo}>
                    <ToggleButtonGroup
                        value={packduration}
                        exclusive
                        onChange={handlePackage}
                        aria-label="text alignment"
                    >
                        <ToggleButton
                            value="yearly"
                            className={classes.togglebtn}
                            style={{
                                borderTopLeftRadius: '2rem',
                                borderBottomLeftRadius: '2rem',
                                border: '1px solid #19C787',
                            }}
                        >
                            <Box component="span">Yearly Billing</Box>
                        </ToggleButton>
                        <ToggleButton
                            value="monthly"
                            className={classes.togglebtn}
                            style={{
                                borderTopRightRadius: '2rem',
                                borderBottomRightRadius: '2rem',
                                border: '1px solid #19C787',
                            }}
                        >
                            <Box component="span">Monthly Billing</Box>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Container>

            <ul className={classes.flexcontainerwrap}>
                {allData && allData.length > 0 ? <PackagesSection /> : null}
                {allData &&
                    allData.length > 0 &&
                    allData
                        .filter((it) => it.subscriptionPlan === 'free forever')
                        .map((i, ind) => (
                            <FreePlan
                                i={i}
                                redirectFn={redirectFn}
                                signupflag={props.signupflag}
                                pagechange={props.pagechange}
                                paymentflag={props.paymentflag}
                                packduration={packduration}
                                paymentFn={paymentFn}
                                upgradeflag={props.upgradeflag}
                                btnindex={btnindex}
                                ind={0}
                                UpgradeFn={UpgradeFn}
                                subsType={props.subsType}
                            />
                        ))}
                {allData &&
                    allData.length > 0 &&
                    allData
                        .filter((it) => it.subscriptionPlan === 'individual')
                        .map((i, ind) => (
                            <IndividualPlan
                                i={i}
                                redirectFn={redirectFn}
                                signupflag={props.signupflag}
                                pagechange={props.pagechange}
                                paymentflag={props.paymentflag}
                                packduration={packduration}
                                paymentFn={paymentFn}
                                upgradeflag={props.upgradeflag}
                                btnindex={btnindex}
                                ind={1}
                                UpgradeFn={UpgradeFn}
                                subsType={props.subsType}
                            />
                        ))}
                {allData &&
                    allData.length > 0 &&
                    allData
                        .filter((it) => it.subscriptionPlan === 'professional')
                        .map((i, ind) => (
                            <ProfessionalPlan
                                i={i}
                                redirectFn={redirectFn}
                                signupflag={props.signupflag}
                                pagechange={props.pagechange}
                                paymentflag={props.paymentflag}
                                packduration={packduration}
                                paymentFn={paymentFn}
                                upgradeflag={props.upgradeflag}
                                btnindex={btnindex}
                                ind={2}
                                UpgradeFn={UpgradeFn}
                                subsType={props.subsType}
                            />
                        ))}
                {allData &&
                    allData.length > 0 &&
                    allData
                        .filter((it) => it.subscriptionPlan === 'team')
                        .map((i, ind) => (
                            <TeamPlan
                                i={i}
                                redirectFn={redirectFn}
                                signupflag={props.signupflag}
                                pagechange={props.pagechange}
                                paymentflag={props.paymentflag}
                                packduration={packduration}
                                paymentFn={paymentFn}
                                upgradeflag={props.upgradeflag}
                                btnindex={btnindex}
                                ind={3}
                                UpgradeFn={UpgradeFn}
                                subsType={props.subsType}
                            />
                        ))}
                {allData &&
                    allData.length > 0 &&
                    allData
                        .filter((it) => it.subscriptionPlan === 'enterprise')
                        .map((i, ind) => (
                            <EnterprisePlan
                                i={i}
                                redirectFn={redirectFn}
                                signupflag={props.signupflag}
                                pagechange={props.pagechange}
                                paymentflag={props.paymentflag}
                                packduration={packduration}
                                paymentFn={paymentFn}
                                upgradeflag={props.upgradeflag}
                                btnindex={btnindex}
                                ind={4}
                                UpgradeFn={UpgradeFn}
                                subsType={props.subsType}
                            />
                        ))}
            </ul>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        seats: state.userProfile.user?.seat,
        subscriptionPlan: state.userProfile.user?.subscriptionPlan,
        subsType: state.userProfile.user?.subsType,
    };
};
export default connect(mapStateToProps)(PaymentPackage);
