import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const add = (props) => {
    return (
        <SvgIcon width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.116 12.8133L18.9566 9.65383C18.5353 9.23257 17.9737 8.98684 17.3769 8.98684H14.5685C13.9717 8.98684 13.4451 9.51341 13.4451 10.1102V12.9186C13.4451 13.5154 13.6909 14.077 14.1121 14.4983L17.2716 17.6577C17.7279 18.1141 18.43 18.1141 18.8864 17.6577L22.116 14.4281C22.5724 13.9717 22.5724 13.2696 22.116 12.8133ZM15.6919 12.0761C15.2355 12.0761 14.8493 11.725 14.8493 11.2335C14.8493 10.7772 15.2355 10.391 15.6919 10.391C16.1833 10.391 16.5344 10.7772 16.5344 11.2335C16.5344 11.725 16.1833 12.0761 15.6919 12.0761ZM1.68503 16.2886V15.411C1.68503 13.726 3.01902 12.3569 4.70405 12.3569C5.23062 12.3569 6.03803 12.9186 7.86348 12.9186C9.65383 12.9186 10.4612 12.3569 10.9878 12.3569C11.2686 12.3569 11.5144 12.4271 11.7601 12.4973V10.7772C11.5144 10.707 11.2686 10.6719 10.9878 10.6719C9.96977 10.6719 9.51341 11.2335 7.86348 11.2335C6.17845 11.2335 5.72209 10.6719 4.70405 10.6719C2.10629 10.6719 0 12.7782 0 15.411V16.2886C0 17.2365 0.737201 17.9737 1.68503 17.9737H14.0419C14.3579 17.9737 14.6738 17.8684 14.9547 17.6928L13.5505 16.2886H1.68503ZM7.86348 10.1102C10.6368 10.1102 12.9186 7.86348 12.9186 5.05509C12.9186 2.28181 10.6368 0 7.86348 0C5.05509 0 2.80839 2.28181 2.80839 5.05509C2.80839 7.86348 5.05509 10.1102 7.86348 10.1102ZM7.86348 1.68503C9.68893 1.68503 11.2335 3.19454 11.2335 5.05509C11.2335 6.91565 9.68893 8.42516 7.86348 8.42516C6.00293 8.42516 4.49342 6.91565 4.49342 5.05509C4.49342 3.19454 6.00293 1.68503 7.86348 1.68503Z"
                fill="#7D8995"
            />
        </SvgIcon>
    );
};
export default add;
