/* eslint-disable */
const INIT_STATE = {
    tempalteName: '',
    formName: '',
    title: '',
    category: '',
    Category: null,
    Category_Count: [],
    Template_By_Category: [],
    type: '',
    content: [],
    shortUrl: '',
    config: {
        language: 'English',
        form_status: '',
        timezone: '',
        password_protection: '',
        new_password: '',
        confirm_password: '',
        hipaa_bidge: '',
        imgsrc: '',
        imgname: '',
        buttontext: 'submit',
        buttonColor: '#fff',
        buttonBackgroundColor: '#3A71D5',
        buttonAlignment: 'left',
        formcolor: '#fff',
        pagecolor: '#f0f0f0',
        pageBackground: '',
        pageBackgroundname: '',
        fontcolor: '#000',
        printbutton: false,
        clearbutton: false,
        titlealignment: 'flex-start',
        subtitle: '',
        titlesize: 24,
        titleimgsrc: '',
        titleimgname: '',
        removeBranding: false,
        fontSize: 16,
        fontSizeCheck: true,
        spacing: 20,
        spacingCheck: true,
    },
    noti_emails: {
        notification_email: 'test@intelyhealth.com',
        confirmation_email: 'test@intelyhealth.com',
    },
    thanku_page: 'Thank you! Your response has been submitted.',
    rateform: '',
    redirect_option: {
        link: 'Thank you! Your response has been submitted.',
        type: '',
    },
    setting: {},
    share: {
        share_link: 'https://form.intelyhealth.com/210607536233045',
        authority: '',
        invite_email: '',
    },
    payment_setting: {
        isConnected: false,
        liveMode: true,
        currency: 'USD',
        productType: 'Sell Products',
        pubKey: null,
        refreshToken: null,
        stripeUserId: null,
        description: 'description',
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_SUBMISSION_FORM_DATA':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
