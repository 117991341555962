import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const add = (props) => {
    return (
        <SvgIcon width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.74341 0C2.98537 0 0 3.05561 0 6.74341C0 9.27219 0.807805 10.0449 5.30341 17.2098C5.97073 18.2634 7.48098 18.2634 8.14829 17.2098C12.6439 10.0449 13.4868 9.27219 13.4868 6.74341C13.4868 3.02049 10.4312 0 6.74341 0ZM6.74341 16.2966C2.2478 9.13171 1.68585 8.67512 1.68585 6.74341C1.68585 3.96878 3.93366 1.68585 6.74341 1.68585C9.51805 1.68585 11.801 3.96878 11.801 6.74341C11.801 8.67512 11.239 9.06146 6.74341 16.2966ZM3.93366 6.74341C3.93366 8.3239 5.16293 9.55317 6.74341 9.55317C8.28878 9.55317 9.55317 8.3239 9.55317 6.74341C9.55317 5.19805 8.28878 3.93366 6.74341 3.93366C5.16293 3.93366 3.93366 5.19805 3.93366 6.74341Z"
                fill="#7D8995"
            />
        </SvgIcon>
    );
};
export default add;
