import axios from 'Utils/axios';
import store from 'redux/store';
import api from 'api';

export const GetPaymentSessionFn = (subid, subsType, noOfseats) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${
                    api.GetPaymentSession
                }?email=${localStorage.getItem('email')}&subsId=${subid}&subsType=${subsType}&seat=${noOfseats} `,
            )
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else if (res.status === 201) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const GetPaymentUpgradeSessionFn = (subid, subsType, noOfseats) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${
                    api.UpgradeSubscriptions
                }?subsId=${subid}&subsType=${subsType}&seat=${noOfseats} `,
            )
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else if (res.status === 201) {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Subscribed successfully.',
                            type: 'success',
                        },
                    });
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const AddseatsFn = (subid, subsType, seat) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${
                    api.AddSeats
                }?subsId=${subid}&subsType=${subsType}&seat=${seat}`,
            )
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Seats added successfully.',
                            type: 'success',
                        },
                    });
                } else if (res.status === 201) {
                    resolve(res.data);
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const LoginWithPaymentFn = (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.LoginWithPayment}`, data)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else if (res.data.code === 400) {
                        resolve(res.data.payload);
                    } else {
                        resolve(res.data.payload);
                    }
                } else {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Error in login .',
                            type: 'error',
                        },
                    });
                }
            })
            .catch((err) => {
                reject(err.response.data.error);

                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const CustomerIdPortalFn = (cid) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${
                    api.CustomerIdPortal
                }?customer_id=${cid}`,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject(res.error);
                    }
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const getsessionplanFn = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.getsessionplan}`)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject(res.error);
                    }
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const AddUserOrganization = (organization) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.UpdateUserOrg}`,

                {
                    organization: organization,
                },
            )
            .then((res) => {
                if (res.data.code === 200) {
                    resolve(res.data.payload);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Organization added successfully.',
                            type: 'success',
                        },
                    });
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const CheckPlanFn = (type, id) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.CheckPlan}`,

                {
                    type: type,
                    orgId: id,
                },
            )
            .then((res) => {
                if (res.data.code === 200) {
                    resolve(res.data.payload);
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err.response.data.code);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: 'Unable to add workspace to current organization',
                        type: 'error',
                    },
                });
            });
    });
};
