import axios from 'Utils/axios';
import store from 'redux/store';
import api from 'api';

export const verfyWorkspaceInvite = (credentials) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.verifyWorkspaceInvite}`,
                credentials,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
                reject(res);
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response.data);
                } else {
                    reject(err);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Server Error !! Please try again later',
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const registerWorkspaceMemeber = (credentials) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.registerWorkspaceMemeber}`,
                credentials,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
                reject(res);
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response);
                } else {
                    reject(err);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Server Error !! Please try again later',
                            type: 'error',
                        },
                    });
                }
            });
    });
};
