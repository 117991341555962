import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { React, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
const useStyles = makeStyles(() => ({
    typodiv: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
const StripePaymentSuccess = () => {
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const params = useParams();
    const { id } = useSelector((state) => ({
        id: state?.createForm?._id,
        paymentSetting: state.createForm?.payment_setting,
    }));
    const formId = id ? id : params.id ? params.id : localStorage.getItem('currentformId');
    const search = useLocation().search;
    const sessionId = new URLSearchParams(search).get('session_id');

    useEffect(() => {
        setLoading(true);
        localStorage.setItem('sessionId', sessionId);
        if (sessionId) {
            window.location.replace(`${window.location.origin}/myform/${formId}`);
            setLoading(false);
        }
    }, [sessionId]);

    return (
        <>
            {loading === true && (
                <div className={classes.typodiv}>
                    <div>
                        <CircularProgress />
                    </div>
                </div>
            )}
        </>
    );
};

export default StripePaymentSuccess;
