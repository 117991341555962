import React from 'react';
import { Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import SubPlanButton from './SubPlanButton';
const useStyles = makeStyles((theme) => ({
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
        height: '6rem',
    },
    Cardclass: {
        borderRadius: 15,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '192px 102rem',
        width: '192px',
        height: '102rem',
        boxShadow: '0px 12px 18px -6px rgb(0 0 0 / 30%)',
    },
    Cardclassmain: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '192px 102rem',
        width: '192px',
        height: '102rem',
    },
    cardHeader: {
        color: '#FFFFFF',
        textTransform: 'capitalize',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
    },
    divider: {
        height: 2,
        background: '#f0f0f0',
        margin: 20,
    },
    contentbox1: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: '192px 265px',
        width: '192px',
        height: '265px',
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    flexItem: {
        marginTop: '1rem',
        textAlign: 'center',
    },
    divider1: {
        height: 2,
        background: '#f0f0f0',
        marginLeft: 20,
        marginRight: 20,
    },
    typoclass: {
        fontSize: '16px',
        color: '#666',
        paddingBottom: '10px',
        fontWeight: '500',
    },
}));
const ProfessionalPlan = (props) => {
    const classes = useStyles();
    const {
        i,
        signupflag,
        pagechange,
        paymentflag,
        packduration,
        paymentFn,
        upgradeflag,
        btnindex,
        ind,
        subsType,
        UpgradeFn,
        redirectFn,
    } = props;

    return (
        <li className={classes.flexItem}>
            <Box component="div" className={classes.Cardclassmain}>
                <Box component="div" className={classes.Cardclass}>
                    <Box component="div" className={classes.cardHeader} style={{ backgroundColor: '#19C787' }}>
                        <Typography
                            style={{
                                textAlign: 'center',
                                fontSize: 26,
                                fontWeight: 'bold',
                            }}
                        >
                            {i.subscriptionPlan}
                        </Typography>
                    </Box>

                    <Box component="div" className={classes.cardPricing} style={{ paddingTop: '20px' }}>
                        <Typography>
                            {packduration === 'yearly' ? (
                                <Box
                                    component="span"
                                    style={{
                                        textAlign: 'center',
                                        fontSize: 42,
                                        color: '#19C787',
                                    }}
                                >
                                    ${i.subType[1].price / 12}
                                </Box>
                            ) : (
                                <Box
                                    component="span"
                                    style={{
                                        textAlign: 'center',
                                        fontSize: 42,
                                        color: '#19C787',
                                    }}
                                >
                                    ${i.subType[0].price}
                                </Box>
                            )}
                            {packduration === 'monthly' ? (
                                <Box component="span" style={{ fontSize: 13 }}>
                                    <br></br>
                                    /mo, billed monthly
                                </Box>
                            ) : (
                                <Box component="span" style={{ fontSize: 13 }}>
                                    <br></br>
                                    /mo, billed annually
                                </Box>
                            )}
                        </Typography>
                    </Box>
                    <Divider classes={{ root: classes.divider }} />
                    <Box component="div" style={{ textAlign: 'center', padding: 10 }}>
                        <Typography className={classes.typoclass}> {i.subsConfig.nbrOfForm} Forms</Typography>
                        <Typography className={classes.typoclass}>
                            {i.subsConfig.nbrOfSubmission} Submissions
                        </Typography>
                        <Typography className={classes.typoclass}> X</Typography>
                        <Typography className={classes.typoclass}>2 Users</Typography>
                    </Box>
                    <Divider classes={{ root: classes.divider1 }} />
                    <Box component="div" style={{ textAlign: 'center', padding: 10, paddingTop: 34 }}>
                        <Typography style={{ paddingBottom: '10px' }}></Typography>
                        <Typography className={classes.typoclass} style={{ color: '#19C787' }}>
                            ✓
                        </Typography>
                        <Typography className={classes.typoclass} style={{ color: '#19C787' }}>
                            ✓
                        </Typography>
                        <Typography className={classes.typoclass} style={{ color: '#19C787' }}>
                            ✓
                        </Typography>
                        <Typography className={classes.typoclass} style={{ color: '#19C787' }}>
                            ✓
                        </Typography>
                        <Typography className={classes.typoclass} style={{ color: '#19C787' }}>
                            ✓
                        </Typography>
                        <Typography className={classes.typoclass} style={{ color: '#19C787' }}>
                            ✓
                        </Typography>
                    </Box>
                    <Divider classes={{ root: classes.divider1 }} />
                    <Box
                        component="div"
                        style={{
                            textAlign: 'center',
                            padding: 10,
                            paddingTop: 34,
                            paddingBottom: 57,
                        }}
                    >
                        <Typography style={{ fontWeight: 'bolder', paddingBottom: '10px' }}></Typography>
                        <Typography className={classes.typoclass} style={{ color: '#19C787' }}>
                            ✓
                        </Typography>
                        <Typography className={classes.typoclass} style={{ color: '#19C787' }}>
                            ✓
                        </Typography>
                        <Typography className={classes.typoclass} style={{ color: '#19C787' }}>
                            ✓
                        </Typography>
                        <Typography className={classes.typoclass} style={{ color: '#19C787' }}>
                            ✓
                        </Typography>
                        <Typography className={classes.typoclass} style={{ paddingTop: 25 }}></Typography>
                        <Typography className={classes.typoclass} style={{ color: '#19C787' }}>
                            ✓
                        </Typography>
                        <Typography className={classes.typoclass}></Typography>
                        <Typography className={classes.typoclass}></Typography>
                    </Box>
                    <Divider classes={{ root: classes.divider1 }} />
                    <Box
                        component="div"
                        style={{
                            textAlign: 'center',
                            padding: 10,
                            paddingTop: 34,
                            paddingBottom: 131,
                        }}
                    >
                        <Typography style={{ fontWeight: 'bolder', paddingBottom: '10px' }}></Typography>
                        <Typography className={classes.typoclass} style={{ color: '#19C787' }}>
                            ✓
                        </Typography>
                        <Typography className={classes.typoclass} style={{ color: '#19C787' }}>
                            ✓
                        </Typography>
                        <Typography className={classes.typoclass}></Typography>
                        <Typography className={classes.typoclass}></Typography>
                        <Typography className={classes.typoclass}></Typography>
                        <Typography className={classes.typoclass}></Typography>
                    </Box>
                    <Divider classes={{ root: classes.divider1 }} />
                    <Box
                        component="div"
                        style={{
                            textAlign: 'center',
                            padding: 10,
                            paddingTop: 34,
                            paddingBottom: 81,
                        }}
                    >
                        <Typography style={{ fontWeight: 'bolder', paddingBottom: '10px' }}></Typography>
                        <Typography className={classes.typoclass} style={{ color: '#19C787' }}>
                            ✓
                        </Typography>
                        <Typography className={classes.typoclass}></Typography>
                        <Typography className={classes.typoclass}></Typography>
                    </Box>
                    <Divider classes={{ root: classes.divider1 }} />
                    <Box
                        component="div"
                        style={{
                            textAlign: 'center',
                            padding: 10,
                            paddingTop: 34,
                            paddingBottom: 49,
                        }}
                    >
                        <Typography style={{ fontWeight: 'bolder', paddingBottom: '10px' }}></Typography>
                        <Typography className={classes.typoclass} style={{ color: '#19C787' }}>
                            ✓
                        </Typography>
                        <Typography className={classes.typoclass}></Typography>
                        <Typography className={classes.typoclass}></Typography>
                    </Box>
                    <SubPlanButton
                        i={i}
                        redirectFn={redirectFn}
                        signupflag={signupflag}
                        pagechange={pagechange}
                        paymentflag={paymentflag}
                        packduration={packduration}
                        paymentFn={paymentFn}
                        upgradeflag={upgradeflag}
                        btnindex={btnindex}
                        ind={ind}
                        UpgradeFn={UpgradeFn}
                        subsType={subsType}
                    />
                </Box>
            </Box>
        </li>
    );
};

export default ProfessionalPlan;
