import DropdownIcon from '@material-ui/icons/ArrowDropDownCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import Paragraphicon from '@material-ui/icons/FormatTextdirectionLToR';
import GridOnIcon from '@material-ui/icons/GridOn';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import RadioIcon from '@material-ui/icons/RadioButtonChecked';
import StarRateIcon from '@material-ui/icons/StarBorderOutlined';
import { PictureAsPdfSharp, VideoFile } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import ImageIcon from '@mui/icons-material/Image';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import AddressIcon from 'assets/icons/addressicon';
import EmbededCodeIcon from 'assets/icons/embededcodeicon';
import FileIcon from 'assets/icons/fileuploadicon';
import Header from 'assets/icons/header';
import HipaaAccountNumberIcon from 'assets/icons/hipaaIcon/accountNoIcon';
import HipaaAddressIcon from 'assets/icons/hipaaIcon/address';
import HipaaAdmissionDateIcon from 'assets/icons/hipaaIcon/admissionDate';
import HipaaAppointmentDateIcon from 'assets/icons/hipaaIcon/appointmentDate';
import HipaaDateIcon from 'assets/icons/hipaaIcon/date';
import HipaaDischargeDateIcon from 'assets/icons/hipaaIcon/dischargeDate';
import HipaaEmailAddressIcon from 'assets/icons/hipaaIcon/emailAddress';
import HipaaFaxNoIcon from 'assets/icons/hipaaIcon/faxNo';
import HipaaInsuranceNoIcon from 'assets/icons/hipaaIcon/insuranceNo';
import HipaaLicenseNoIcon from 'assets/icons/hipaaIcon/licenseNo';
import HipaaMedicalRecordNoIcon from 'assets/icons/hipaaIcon/medicalRecordNo';
import HipaaNameIcon from 'assets/icons/hipaaIcon/name';
import HipaaPhoneNoIcon from 'assets/icons/hipaaIcon/phoneNo';
import HipaaSecurityNoIcon from 'assets/icons/hipaaIcon/securityNo';
import InputNumberIcon from 'assets/icons/inputnumbericon';
import NameIcon from 'assets/icons/NameIcon';
import PhoneIcon from 'assets/icons/phoneicon';
import Qrcode from 'assets/icons/Qrcode';
import ShortAnswer from 'assets/icons/ShortAnswer';
import SignatureIcon from 'assets/icons/signatureicon';
import ID from 'builder/UUID';
import PaidIcon from '@mui/icons-material/Paid';
import ObjectID from 'bson-objectid';

export const defaultWidgets = () => {
    return [
        {
            key: 'Header',
            name: 'Heading',
            text: 'Header Title',
            propertyHeading: 'Header Title',
            icon: Header,
            show: true,
            static: true,
            basic: true,
            isHipaa: false,
            uuid: 'IFE01',
            label: 'Heading',
            placeholder: 'Add a subheading...',
            type: 'label',
            returnType: 'none',
        },
        {
            key: 'ShortAnswer',
            name: 'Short Text',
            text: 'Short Text',
            propertyHeading: 'Short Text',
            icon: ShortAnswer,
            static: true,
            basic: true,
            isHipaa: false,
            uuid: 'IFE02',
            label: 'Short Answer',
            placeholder: 'Placeholder Text...',
            type: 'text',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: 'Short Answer',
            },
        },
        {
            key: 'PageBreak',
            name: 'PageBreak',
            text: 'PageBreak',
            icon: InsertPageBreakIcon,
            static: true,
            isHipaa: false,
            show1: true,
            uuid: 'IFE03',
            label: 'PageBreak',
            placeholder: '',
            type: 'multimedia',
        },
        {
            key: 'LongAnswer',
            name: 'Long Text',
            text: 'Long Text',
            propertyHeading: 'Long Text',
            icon: ShortAnswer,
            static: true,
            basic: true,
            isHipaa: false,
            uuid: 'IFE013',
            label: 'Long Answer',
            placeholder: 'Placeholder Text...',
            type: 'text',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: 'Long Answer',
            },
        },
        {
            key: 'Paragraph',
            name: 'Paragraph',
            text: 'Paragraph',
            propertyHeading: 'Paragraph',
            icon: Paragraphicon,
            static: true,
            basic: true,
            isHipaa: false,
            uuid: 'IFE017',
            label: 'Paragraph',
            placeholder: 'Placeholder Text...',
            type: 'text',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: '<p>My Paragraph<p>',
            },
        },
        {
            key: 'Name',
            name: 'Name',
            text: 'Name',
            propertyHeading: 'Name',
            icon: NameIcon,
            static: true,
            basic: true,
            isHipaa: false,
            uuid: 'IFE04',
            label: 'Name',
            placeholderFname: 'First Name',
            placeholderLname: 'Last Name',
            type: 'text',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: 'john doe',
            },
        },
        {
            key: 'Address',
            name: 'Address',
            text: 'Address',
            propertyHeading: 'Address',
            icon: AddressIcon,
            static: true,
            basic: true,
            isHipaa: false,
            uuid: 'IFE05',
            label: 'Address',
            address: 'Address',
            streetadd: 'Street address',
            apart: 'Apartment, Suite, etc.',
            city: 'City',
            state: 'State/Province',
            country: 'Country',
            postal: 'ZIP/Postal Code',
            address_placeholder: 'Type street address',
            apart_placeholder: 'Apartment, Suite, etc.',
            city_placeholder: 'Type city name.',
            state_placeholder: 'State/Province',
            country_placeholder: 'Type country name',
            postal_placeholder: 'Postal Code',
            type: 'text',
            returnType: 'Object',
            returnKey: 'other',
            sampleReturnStructure: {
                other: {
                    address: 'address',
                    apartment: 'apartment',
                    city: 'city',
                    country: 'country',
                    postal: '654321',
                    state: 'state',
                },
            },
            addressIsRequired: false,
            apartmentIsRequired: false,
            cityIsRequired: false,
            stateIsRequired: false,
            countryIsRequired: false,
            zipIsRequired: false,
        },
        {
            key: 'Email',
            name: 'Email Address',
            text: 'Email Address',
            propertyHeading: 'Email Address',
            basic: true,
            isHipaa: false,
            static: true,
            uuid: 'IFE06',
            label: 'Email',
            placeholder: 'example@email.com',
            type: 'email',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: 'example@email.com',
            },
        },
        {
            key: 'Phone',
            name: 'Phone Number',
            text: 'Phone Number',
            propertyHeading: 'Phone Number',
            icon: PhoneIcon,
            static: true,
            basic: true,
            isHipaa: false,
            uuid: 'IFE08',
            label: 'Phone',
            placeholder: '(000) 000-0000',
            type: 'number',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: '(000) 000-0000',
            },
        },
        {
            key: 'InputNumber',
            name: 'Number',
            text: 'Number',
            propertyHeading: 'Number',
            icon: InputNumberIcon,
            static: true,
            uuid: 'IFE09',
            basic: true,
            isHipaa: false,
            label: 'Number',
            placeholder: '1234567890',
            type: 'number',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: '1234567890',
            },
        },
        {
            key: 'DateRange',
            name: 'Date',
            text: 'Date',
            propertyHeading: 'Date',
            icon: DateRangeIcon,
            static: true,
            show1: true,
            isHipaa: false,
            uuid: 'IFE011',
            label: 'Date',
            placeholder: 'Placeholder Text...',
            type: 'date',
            returnType: 'Date',
            returnKey: 'dateValue',
            sampleReturnStructure: {
                dateValue: '2022-05-27T00:00:00.000Z',
            },
        },
        {
            key: 'FileUpload',
            name: 'File Upload',
            icon: FileIcon,
            text: 'File Upload',
            propertyHeading: 'File Upload',
            static: true,
            isHipaa: false,
            uuid: 'IFE0112',
            label: 'File',
            placeholder: 'Placeholder Text...',
            type: 'source file',
            returnType: 'Array',
            returnKey: 'selectedOptions',
            sampleReturnStructure: {
                selectedOptions: ['file path1', 'file path2'],
            },
        },
        {
            key: 'ImageUpload',
            name: 'Image Upload',
            icon: PhotoCameraOutlinedIcon,
            text: 'Image Upload',
            propertyHeading: 'Image Upload',
            static: true,
            isHipaa: false,
            uuid: 'IFE010',
            label: 'ImageUpload',
            type: 'source file',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: 'Image path',
            },
        },
        {
            key: 'EmbededCode',
            name: 'Embed Code',
            icon: EmbededCodeIcon,
            text: 'Embed Code',
            propertyHeading: 'Embed Code',
            static: true,
            isHipaa: false,
            uuid: 'IFE0111',
            label: 'Embed Code',
            placeholder: 'Your Code...',
            type: 'text',
            returnType: 'none',
        },
        {
            key: 'Signature',
            canReadOnly: true,
            isHipaa: false,
            name: 'Signature',
            icon: SignatureIcon,
            text: 'Signature',
            propertyHeading: 'Signature',
            label: 'Signature',
            field_name: 'signature_',
            uuid: 'IFE012',
            placeholder: 'Placeholder Text...',
            type: 'signature',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: 'Signature',
            },
        },
        {
            key: 'Rating',
            name: 'Rating',
            icon: StarRateIcon,
            text: 'Rating',
            propertyHeading: 'Rating',
            uuid: 'IFE0131',
            isHipaa: false,
            label: 'ImageUpload',
            placeholder: 'Type a question',
            type: 'rating',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: '3.5',
            },
        },
        {
            key: 'RadioButtons',
            name: 'Single-Select',
            text: 'Single-Select',
            propertyHeading: 'Single-Select',
            isHipaa: false,
            icon: RadioIcon,
            static: true,
            uuid: 'IFE014',
            label: 'Radio',
            placeholder: '',
            options: ['Option 1'],
            type: 'multiple',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: 'Option1',
            },
        },
        {
            key: 'Checkboxes',
            name: 'Multi-Select',
            text: 'Multi-Select',
            propertyHeading: 'Multi-Select',
            isHipaa: false,
            icon: CheckBoxIcon,
            static: true,
            uuid: 'IFE015',
            label: 'Checkbox',
            placeholder: '',
            options: ['Option 1'],
            type: 'multiple',
            returnType: 'Array',
            returnKey: 'selectedOptions',
            sampleReturnStructure: {
                selectedOptions: ['Option1', 'Option2'],
            },
        },
        {
            key: 'Dropdown',
            name: 'Dropdown',
            text: 'Dropdown',
            propertyHeading: 'Dropdown',
            isHipaa: false,
            icon: DropdownIcon,
            static: true,
            uuid: 'IFE016',
            label: 'Dropdown',
            placeholder: '',
            options: ['Option 1'],
            selectedvalue: 0,
            type: 'multiple',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: 'Option1',
            },
        },

        {
            key: 'MyCaptcha',
            name: 'Captcha',
            text: 'Captcha',
            propertyHeading: 'Captcha',
            icon: FlipCameraAndroidIcon,
            static: true,
            uuid: 'IFE018',
            isHipaa: false,
            label: 'Captcha',
            placeholder: 'Please verify that you are human',
            type: 'captcha',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: 'Yes',
            },
        },
        {
            key: 'EmbedImage',
            name: 'Embed Image',
            text: 'Embed Image',
            propertyHeading: 'Embed Image',
            icon: ImageIcon,
            static: true,
            isHipaa: false,
            uuid: 'IFE020',
            label: 'Embed Image',
            type: 'multimedia',
            returnType: 'none',
        },
        {
            key: 'MyAppointment',
            name: 'Appointment',
            text: 'Appointment',
            propertyHeading: 'Appointment',
            icon: CalendarTodayIcon,
            static: true,
            isHipaa: false,
            uuid: 'IFE021',
            label: 'Appointment',
            type: 'date',
            returnType: 'Object',
            returnKey: 'appointmentData',
            sampleReturnStructure: {
                appointmentData: {
                    startDate: '2022-05-30T04:45:00.000Z',
                    endDate: '2022-05-30T05:00:00.000Z',
                    timeZone: 'Asia/Calcutta',
                },
            },
        },
        {
            key: 'MyTermsAndConditions',
            name: 'Terms and Conditions',
            text: 'Terms and Conditions',
            propertyHeading: 'Terms and Conditions',
            icon: AssignmentIcon,
            static: true,
            isHipaa: false,
            uuid: 'IFE022',
            label: 'I agree to',
            placeholder: 'terms & conditions',
            type: 'link',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: 'Yes',
            },
        },
        {
            key: 'MyQrCode',
            name: 'QR code',
            text: 'QR code',
            propertyHeading: 'QR code',
            icon: Qrcode,
            static: true,
            isHipaa: false,
            uuid: 'IFE023',
            label: 'QR code',
            placeholder: 'Please verify QR code',
            type: 'qrcode',
            returnType: 'none',
        },
        {
            key: 'EmbedVideo',
            name: 'Embed Video',
            text: 'Embed Video',
            propertyHeading: 'Embed Video',
            icon: VideoFile,
            static: true,
            isHipaa: false,
            uuid: 'IFE024',
            label: 'Embed Video',
            placeholder: '',
            type: 'multimedia',
            returnType: 'none',
        },
        {
            key: 'DataGrid',
            name: 'Data Grid',
            text: 'Data Grid',
            propertyHeading: 'Data Grid',
            icon: GridOnIcon,
            static: true,
            isHipaa: false,
            uuid: 'IFE025',
            label: 'Data Grid',
            type: 'data grid',
            returnType: 'none',
        },
        {
            key: 'EmbedPdf',
            name: 'Embed PDF',
            text: 'Embed PDF',
            propertyHeading: 'Embed PDF',
            icon: PictureAsPdfSharp,
            static: true,
            uuid: 'IFE026',
            isHipaa: false,
            label: 'Embed Pdf',
            placeholder: '',
            type: 'embed pdf',
            returnType: 'none',
        },
        {
            key: 'ProgressBar',
            name: 'Progress Bar',
            text: 'Progress Bar',
            propertyHeading: 'Progress Bar',
            isHipaa: false,
            icon: HorizontalRuleIcon,
            static: true,
            uuid: 'IFE028',
            label: 'Progress Bar',
            placeholder: '',
            type: 'progress bar',
            returnType: 'none',
        },
        {
            key: 'HIPAA_Name',
            name: 'Name',
            text: 'Name',
            propertyHeading: 'Name',
            icon: HipaaNameIcon,
            static: true,
            isHipaa: true,
            uuid: 'IFE029',
            label: 'Name',
            hipaaPlaceholderFname: 'First Name',
            hipaaPlaceholderLname: 'Last Name',
            type: 'text',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: 'john doe',
            },
        },
        {
            key: 'HIPAA_DateRange',
            name: 'Date',
            text: 'Date',
            propertyHeading: 'Date',
            icon: HipaaDateIcon,
            static: true,
            isHipaa: true,
            show1: true,
            uuid: 'IFE030',
            label: 'Date',
            placeholder: 'Placeholder Text...',
            type: 'date',
            returnType: 'Date',
            returnKey: 'dateValue',
            sampleReturnStructure: {
                dateValue: '2022-05-26T00:00:00.000Z',
            },
        },
        {
            key: 'HIPAA_Phone',
            name: 'Phone Number',
            text: 'Phone Number',
            propertyHeading: 'Phone Number',
            icon: HipaaPhoneNoIcon,
            static: true,
            isHipaa: true,
            uuid: 'IFE031',
            label: 'Phone',
            placeholder: '(000) 000-0000',
            type: 'number',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: '(000) 000-0000',
            },
        },
        {
            key: 'HIPAA_Address',
            name: 'Address',
            text: 'Address',
            propertyHeading: 'Address',
            icon: HipaaAddressIcon,
            static: true,
            isHipaa: true,
            uuid: 'IFE032',
            label: 'Address',
            hipaaaddress: 'Address',
            hipaastreetadd: 'Street address',
            hipaaapart: 'Apartment, Suite, etc.',
            hipaacity: 'City',
            hipaastate: 'State/Province',
            hipaacountry: 'Country',
            hipaapostal: 'ZIP/Postal Code',
            hipaaaddress_placeholder: 'Type street address',
            hipaaapart_placeholder: 'Apartment, Suite, etc.',
            hipaacity_placeholder: 'Type city name.',
            hipaastate_placeholder: 'State/Province',
            hipaacountry_placeholder: 'Type country name',
            hipaapostal_placeholder: 'Postal Code',
            type: 'text',
            returnType: 'Object',
            returnKey: 'other',
            sampleReturnStructure: {
                other: {
                    address: 'address',
                    apartment: 'apartment',
                    city: 'city',
                    country: 'country',
                    postal: '654321',
                    state: 'state',
                },
            },
        },
        {
            key: 'PersonnelProfile',
            name: 'Personnel Profile',
            text: 'Personnel Profile',
            propertyHeading: 'Personnel Profile',
            icon: AccountCircleIcon,
            static: true,
            isHipaa: false,
            placeholder: '',
            uuid: 'IFE043',
            label: 'Personnel Profile',
            type: 'multiple',
            profileData: [],
            returnType: 'Object',
            returnKey: 'personalProfileData',
            sampleReturnStructure: {
                personalProfileData: {
                    name: 'john doe',
                    description: 'description',
                    imageUrl: 'image path',
                },
            },
        },
        {
            key: 'Email',
            name: 'Email Address', // middle label
            text: 'Email Address', // left element
            propertyHeading: 'Email Address',
            basic: true,
            isHipaa: true,
            icon: HipaaEmailAddressIcon,
            static: true,
            uuid: 'IFE033',
            label: 'Email', // right label
            placeholder: 'example@email.com',
            type: 'email',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: 'example@email.com',
            },
        },
        {
            key: 'HIPAA_Phone',
            name: 'Fax Number',
            text: 'Fax Number',
            propertyHeading: 'Fax Number',
            icon: HipaaFaxNoIcon,
            static: true,
            isHipaa: true,
            uuid: 'IFE034',
            label: 'Fax Number',
            placeholder: '(000) 000-0000',
            type: 'number',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: '(000) 000-0000',
            },
        },
        {
            key: 'InputNumber',
            name: 'Social Security Number',
            text: 'Social Security Number',
            propertyHeading: 'Social Security Number',
            icon: HipaaSecurityNoIcon,
            static: true,
            uuid: 'IFE035',
            basic: true,
            isHipaa: true,
            label: 'Social Security Number',
            placeholder: 'XXX-XX-XXXX',
            type: 'number',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: '123-45-6789',
            },
        },
        {
            key: 'InputNumber',
            name: 'Insurance Beneficiary Number',
            text: 'Insurance Beneficiary Number',
            propertyHeading: 'Social Security Number',
            icon: HipaaInsuranceNoIcon,
            static: true,
            uuid: 'IFE036',
            basic: true,
            isHipaa: true,
            label: 'Insurance Beneficiary Number',
            placeholder: '1234567890',
            type: 'number',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: '1234567890',
            },
        },
        {
            key: 'InputNumber',
            name: 'Medical Record Number',
            text: 'Medical Record Number',
            propertyHeading: 'Medical Record Number',
            icon: HipaaMedicalRecordNoIcon,
            static: true,
            uuid: 'IFE037',
            basic: true,
            isHipaa: true,
            label: 'Medical Record Number',
            placeholder: '1234567890',
            type: 'number',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: '1234567890',
            },
        },
        {
            key: 'InputNumber',
            name: 'Account Number',
            text: 'Account Number',
            propertyHeading: 'Account Number',
            icon: HipaaAccountNumberIcon,
            static: true,
            uuid: 'IFE038',
            basic: true,
            isHipaa: true,
            label: 'Account Number',
            placeholder: '1234567890',
            type: 'number',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: '1234567890',
            },
        },
        {
            key: 'InputNumber',
            name: 'License Number',
            text: 'License Number',
            propertyHeading: 'License Number',
            icon: HipaaLicenseNoIcon,
            static: true,
            uuid: 'IFE039',
            basic: true,
            isHipaa: true,
            label: 'License Number',
            placeholder: '1234567890',
            type: 'number',
            returnType: 'String',
            returnKey: 'textValue',
            sampleReturnStructure: {
                textValue: '1234567890',
            },
        },
        {
            key: 'HIPAA_DateRange',
            name: 'Appointment Date',
            text: 'Appointment Date',
            propertyHeading: 'Appointment Date',
            icon: HipaaAppointmentDateIcon,
            static: true,
            isHipaa: true,
            show1: true,
            uuid: 'IFE040',
            label: 'Appointment Date',
            placeholder: 'Placeholder Text...',
            type: 'date',
            returnType: 'Date',
            returnKey: 'dateValue',
            sampleReturnStructure: {
                dateValue: '2022-05-27T00:00:00.000Z',
            },
        },
        {
            key: 'HIPAA_DateRange',
            name: 'Admission Date',
            text: 'Admission Date',
            propertyHeading: 'Admission Date',
            icon: HipaaAdmissionDateIcon,
            static: true,
            isHipaa: true,
            show1: true,
            uuid: 'IFE041',
            label: 'Admission Date',
            placeholder: 'Placeholder Text...',
            type: 'date',
            returnType: 'Date',
            returnKey: 'dateValue',
            sampleReturnStructure: {
                dateValue: '2022-05-27T00:00:00.000Z',
            },
        },
        {
            key: 'HIPAA_DateRange',
            name: 'Discharge Date',
            text: 'Discharge Date',
            propertyHeading: 'Discharge Date',
            icon: HipaaDischargeDateIcon,
            static: true,
            isHipaa: true,
            show1: true,
            uuid: 'IFE042',
            label: 'Discharge Date',
            placeholder: 'Placeholder Text...',
            type: 'date',
            returnType: 'Date',
            returnKey: 'dateValue',
            sampleReturnStructure: {
                dateValue: '2022-05-27T00:00:00.000Z',
            },
        },
        {
            key: 'StripeCheckout',
            name: 'Stripe Checkout',
            text: 'Stripe Checkout',
            propertyHeading: 'Stripe Checkout',
            isHipaa: false,
            icon: PaidIcon,
            static: true,
            uuid: 'IFE027',
            label: 'Stripe Checkout',
            placeholder: 'Please enter amount',
            type: 'payment',
            returnType: 'none',
        },
        {
            key: 'Scheduling',
            name: 'Scheduling',
            text: 'Scheduling',
            propertyHeading: 'Scheduling',
            icon: CalendarTodayIcon,
            static: true,
            isHipaa: false,
            uuid: 'IFE044',
            label: 'Scheduling',
            type: 'date',
            returnType: 'Object',
            returnKey: 'schedulingData',
            sampleReturnStructure: {
                schedulingData: {
                    calendarId: '62c586722b3d1376e6315e9f',
                    startDate: '2022-05-30T04:45:00.000Z',
                    endDate: '2022-05-30T05:00:00.000Z',
                    timeZone: 'Asia/Calcutta',
                },
            },
        },
    ];
};

export const create = (item) => {
    const elementOptions = {
        _id: ObjectID(),
        id: ID.uuid(),
        element: item.element || item.key,
        text: item.name,
        static: item.static,
        uuid: item.uuid,
        propertyHeading: item.propertyHeading,
        placeholder: item.placeholder,
        returnType: item.returnType,
        returnKey: item.returnKey,
        sampleReturnStructure: item.sampleReturnStructure,
        isHipaa: item.isHipaa,
        options: item.options,
        profileData: [],
        selectedvalue: item.selectedvalue,
        label: item.label,
        showDescription: item.showDescription,
        config: {
            address: item.address,
            streetadd: item.streetadd,
            apart: item.apart,
            city: item.city,
            state: item.state,
            country: item.country,
            postal: item.postal,
            address_placeholder: item.address_placeholder,
            apart_placeholder: item.apart_placeholder,
            city_placeholder: item.city_placeholder,
            state_placeholder: item.state_placeholder,
            country_placeholder: item.country_placeholder,
            postal_placeholder: item.postal_placeholder,
            placeholderFname: item.placeholderFname,
            placeholderLname: item.placeholderLname,
            hipaaPlaceholderFname: item.hipaaPlaceholderFname,
            hipaaPlaceholderLname: item.hipaaPlaceholderLname,
            hipaatimeoption: false,
            hipaacountryCode: 'us',
            hipaawithCountryCode: false,
            hipaaaddress: item.hipaaaddress,
            hipaastreetadd: item.hipaastreetadd,
            hipaaapart: item.hipaaapart,
            hipaacity: item.hipaacity,
            hipaastate: item.hipaastate,
            hipaacountry: item.hipaacountry,
            hipaapostal: item.hipaapostal,
            hipaaaddress_placeholder: item.hipaaaddress_placeholder,
            hipaaapart_placeholder: item.hipaaapart_placeholder,
            hipaacity_placeholder: item.hipaacity_placeholder,
            hipaastate_placeholder: item.hipaastate_placeholder,
            hipaacountry_placeholder: item.hipaacountry_placeholder,
            hipaapostal_placeholder: item.hipaapostal_placeholder,
            hipaaShowStreet: true,
            hipaashowapartment: true,
            hipaashowcity: true,
            hipaashowstateprovince: true,
            hipaashowcountry: true,
            hipaashowzip: true,
            embedCode: '',
            embedCodeHeight: 200,
            embedCodeWidth: 706,
            alignment:
                item.key === 'RadioButtons' ||
                item.key === 'Checkboxes' ||
                item.key === 'MyAppointment' ||
                item.key === 'DataGrid' ||
                item.key === 'StripeCheckout' ||
                item.key === 'Scheduling'
                    ? 'top'
                    : 'left',
            multifile: 'no',
            numberoffile: 10,
            typeoffile: [],
            imageoffile: [],
            imageSize: 2,
            predefinedDropdown: '',
            predefinedCheckbox: '',
            predefinedRadiobtn: '',
            selectedByDefaultdropdown: '',
            selectedByDefaultCheckbox: '',
            selectedByDefaultRadio: '',
            fileSize: 5,
            coloumnsize: 1,
            rowsize: 1,
            browsetext: 'Browse',
            headtextsize: 22,
            headimgsrc: '',
            imagesrc: '',
            headimgname: '',
            imgname: '',
            textboxsize: null,
            timeoption: false,
            showStreet: true,
            showapartment: true,
            showcity: true,
            showstateprovince: true,
            showcountry: true,
            showzip: true,
            required: false,
            textboxsizeshow: true,
            customMinshow: true,
            maxLengthshow: true,
            hidden: false,
            RestrictSameDayBooking: false,
            maxlength: null,
            ratingAmount: 5,
            ratingIconValue: 1,
            captchaval: 'No',
            logowidth: 100,
            logoheight: 100,
            titlelogowidth: 100,
            titlelogoheight: 100,
            numberstatus: 'None',
            numberValue1: '',
            numberValue2: '',
            withCountryCode: false,
            numberMsgErr: '',
            textStatus: 'None',
            textDiscription: '',
            termslink: '',
            removelink: false,
            textMsgErr: '',
            countryCode: 'us',
            startTime: '',
            endTime: '',
            startDate: '',
            endDate: '',
            AppointmentSetting: {},
            AppointmentStartDate: null,
            AppointmentEndDate: null,
            AppointmentIdentifier: null,
            AppointmentHoldDays: null,
            AppointmentTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            AppointmentResourcePool: null,
            AppointmentIdentifierError: true,
            AppointmentResourcePoolError: true,
            messagetoencode: '',
            Qrcodesize: 128,
            videoheight: 200,
            videowidth: 300,
            videourl: '',
            columnsvalue: 'Col1,Col2,Col3',
            rowsvalue: 'Val1,Val2,Val3\nVal4,Val5,Val6',
            gridtheme: '#4F4F4F',
            bannerImageWidth: 300,
            bannerImageHeight: 200,
            bannerImageSrc: '',
            bannerImageName: '',
            pdfWidth: 700,
            pdfHeight: 500,
            pdfUrl: '',
            pdfUploadfile: '',
            pdfUploadfileName: '',
            pdfUploadMode: 'url',
            progressCountRequiredfield: false,
            progressAlign: 'top',
            progressStrokeColor: '#007fff',
            headertitlecolor: '#000000',
            CalendarId: [],
            hideSpecialtiesSelect: false,
            specialtiesLabel: 'Specialty',
            locationLabel: 'Location',
            personelLabel: 'Provider',
        },
    };

    if (item.type === 'custom') {
        elementOptions.key = item.key;
        elementOptions.custom = true;
        elementOptions.forwardRef = item.forwardRef;

        elementOptions.component = item.component || null;
        elementOptions.custom_options = item.custom_options || [];
    }

    if (item.static) {
        elementOptions.bold = false;
        elementOptions.italic = false;
    }

    if (item.canHaveAnswer) {
        elementOptions.canHaveAnswer = item.canHaveAnswer;
    }

    if (item.canReadOnly) {
        elementOptions.readOnly = false;
    }

    if (item.canDefaultToday) {
        elementOptions.defaultToday = false;
    }

    if (item.content) {
        elementOptions.content = item.content;
    }

    if (item.href) {
        elementOptions.href = item.href;
    }

    elementOptions.canHavePageBreakBefore = item.canHavePageBreakBefore !== false;
    elementOptions.canHaveAlternateForm = item.canHaveAlternateForm !== false;
    elementOptions.canHaveDisplayHorizontal = item.canHaveDisplayHorizontal !== false;
    if (elementOptions.canHaveDisplayHorizontal) {
        elementOptions.inline = item.inline;
    }
    elementOptions.canHaveOptionCorrect = item.canHaveOptionCorrect !== false;
    elementOptions.canHaveOptionValue = item.canHaveOptionValue !== false;
    elementOptions.canPopulateFromApi = item.canPopulateFromApi !== false;

    if (item.class_name) {
        elementOptions.class_name = item.class_name;
    }

    if (item.key === 'Image') {
        elementOptions.src = item.src;
    }

    if (item.key === 'DatePicker') {
        elementOptions.dateFormat = item.dateFormat;
        elementOptions.timeFormat = item.timeFormat;
        elementOptions.showTimeSelect = item.showTimeSelect;
        elementOptions.showTimeSelectOnly = item.showTimeSelectOnly;
    }

    if (item.key === 'Download') {
        elementOptions._href = item._href;
        elementOptions.file_path = item.file_path;
    }

    if (item.key === 'Range') {
        elementOptions.step = item.step;
        elementOptions.default_value = item.default_value;
        elementOptions.min_value = item.min_value;
        elementOptions.max_value = item.max_value;
        elementOptions.min_label = item.min_label;
        elementOptions.max_label = item.max_label;
    }

    if (item.defaultValue) {
        elementOptions.defaultValue = item.defaultValue;
    }

    if (item.field_name) {
        elementOptions.field_name = item.field_name + ID.uuid();
    }

    if (item.label) {
        elementOptions.label = item.label;
    }

    if (item.options) {
        if (item.options.length > 0) {
            elementOptions.options = item.options;
        }
    }

    return elementOptions;
};
