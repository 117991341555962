import api from 'api';
import store from 'redux/store';
import axios from 'Utils/axios';

import { create, defaultWidgets } from 'Utils/formWidgets';
import { setWorkspaceDefaultId } from 'Utils/setWorkspaceId';
export const setFormData = (payload) => (dispatch) => {
    if (payload.content) {
        payload.content = payload.content.filter((i) => i !== null && typeof i === 'object');
    }

    dispatch({ type: 'UPDATE_CREATE_DATA', payload: { ...payload } });
};

export const submitFormData = (data, id, withoutUpdate = false) => {
    return new Promise((resolve, reject) => {
        if (!id) return resolve(data);
        if (!data.title) return resolve(data);

        delete data._id;

        if (data.setting.conditions?.length) {
            for (let i = 0; i < data.setting.conditions.length; i++) {
                let cond = data.setting.conditions[i];

                cond.check = cond.check.filter((i) => {
                    return i.state && i.if;
                });
                cond.result = cond.result.filter((i) => {
                    return i.do && i.field;
                });

                if (!cond.check.length || !cond.result.length) {
                    data.setting.conditions.splice(i, 1);
                } else {
                    data.setting.conditions[i] = cond;
                }
            }
        }

        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.submitFormDataApi}${id}`,

                {
                    ...data,
                },
            )
            .then((res) => {
                resolve(res.data);
                if (res.status === 200) {
                    if (!withoutUpdate)
                        geteditForm(id).then((data) => {
                            store.dispatch({ type: 'UPDATE_CREATE_DATA', payload: data });
                        });
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const createNewForm = (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.createNewFormApi}`,

                {
                    title: data.title,
                    shortUrl: null,
                    config: data.config,
                },
            )
            .then((res) => {
                if (res.data.code === 201) {
                    resolve(res.data.payload);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Form created successfully.',
                            type: 'success',
                        },
                    });
                    store.dispatch({
                        type: 'NEW_FORM',
                    });
                    store.dispatch({
                        type: 'PAYMENT_SETTING',
                        payload: {
                            isConnected: false,
                            liveMode: true,
                            currency: 'USD',
                            productType: 'Sell Products',
                            pubKey: null,
                            refreshToken: null,
                            stripeUserId: null,
                            description: 'description',
                        },
                    });
                } else if (res.data.code === 422) {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Form url already exists.',
                            type: 'error',
                        },
                    });
                    store.dispatch({
                        type: 'START_LOADING',
                        payload: {
                            loading: false,
                        },
                    });
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const getAllForm = () => {
    localStorage.removeItem('latestforminfo');
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.getAllFormApi}`)
            .then((res) => {
                // handle latest form info.

                if (res.data) {
                    if (res.data.code === 206) {
                        //  available latest form in allforms.
                        localStorage.setItem('latestforminfo', 'allform');
                    } else if (res.data.code === 207) {
                        //  available latest form in trash or archive.
                        localStorage.setItem('latestforminfo', res.data.payload);
                    }
                }

                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject(res.error);
                    }
                } else {
                    resolve(res);
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                if (err.response.data.status === 500 || err.response.data.status === 404) {
                    return;
                }
                if (err.response.data.code !== 404) {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const geteditForm = (id) => {
    if (!id) {
        return;
    }

    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.geteditFormApi}${id}`)

            .then((res) => {
                if (res.data.code === 200) {
                    resolve(res.data.payload[0]);
                } else {
                    store.dispatch({
                        type: 'START_LOADING',
                        payload: {
                            loading: false,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err?.response?.data?.code === 404) {
                    window.location.href = '/404';
                } else {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err?.response?.data?.error,
                            type: 'error',
                        },
                    });
                }

                reject(err?.response?.data?.error);
            });
    });
};

export const removeIntegration = (credentials) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.removeIntegration}`,
                credentials,
            )
            .then((res) => {
                if (res.data.code === 200) {
                    resolve(res.data.payload[0]);
                } else {
                    store.dispatch({
                        type: 'START_LOADING',
                        payload: {
                            loading: false,
                        },
                    });
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const getdeleteForm = (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.getdeleteFormApi}`, {
                ids: data,
            })

            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Form deleted successfully.',
                            type: 'success',
                        },
                    });
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const setformdisabled = (data, type) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.disabledFormApi}`, {
                ids: data,
                action_type: type,
            })

            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: type === 'Enabled' ? 'Form enabled successfully.' : 'Form disabled successfully.',
                            type: 'success',
                        },
                    });
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const getAllArchived = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.getAllArchivedApi}`)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject(res.error);
                    }
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                if (err.response.data.code !== 404) {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const getAllDeleted = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.getAllDeletedApi}`)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject(res.error);
                    }
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                if (err.response.data.code !== 404) {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const markedAsArchived = (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.markedAsArchivedApi}`, {
                ids: data,
            })
            .then((res) => {
                resolve(res.data);
                if (res.status === 200) {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Marked as archive successfully.',
                            type: 'success',
                        },
                    });
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const copyForm = (id) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.copyFormApi}${id}`)

            .then((res) => {
                if (res.data.code === 201) {
                    resolve(res.data.payload);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Form copy successfully.',
                            type: 'success',
                        },
                    });
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const recoverForm = (id, data, planLimitInd) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.recoverFormApi}${id}`, {
                isActive: data,
                planLimitInd: planLimitInd,
            })
            .then((res) => {
                resolve(res.data);
                if (res.status === 200) {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Form recovered successfully.',
                            type: 'success',
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.response?.data?.code === 412) {
                    reject(err.response?.data?.code);
                } else {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                }
                reject(err?.response?.data?.error);
            });
    });
};

export const addToFavorite = (data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.addToFavoriteApi}`, {
                ids: data,
            })

            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                        store.dispatch({
                            type: 'UPDATE_SNACK',
                            payload: {
                                snackbar: true,
                                message: 'Form added to favorites successfully.',
                                type: 'success',
                            },
                        });
                    } else {
                        reject(res.error);
                    }
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const getAllFavorite = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.getAllFavoriteApi}`)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject(res.error);
                    }
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                if (err.response.data.code !== 404) {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const removeFromTheFavorite = (id) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.removeFromTheFavoriteApi}${id}`,
            )

            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                        store.dispatch({
                            type: 'UPDATE_SNACK',
                            payload: {
                                snackbar: true,
                                message: 'Form removed from favorites successfully.',
                                type: 'success',
                            },
                        });
                    } else {
                        reject(res.error);
                    }
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const UnArchive = (id, data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.UnArchiveApi}${id}`,

                {
                    isArchived: data,
                },
            )
            .then((res) => {
                resolve(res.data);
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                        store.dispatch({
                            type: 'UPDATE_SNACK',
                            payload: {
                                snackbar: true,
                                message: 'Form removed from archive successfully.',
                                type: 'success',
                            },
                        });
                    } else {
                        reject(res.error);
                    }
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const sendInvite = (id, emails) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.sendInvite}`,

                {
                    id: id,
                    email: emails,
                },
            )
            .then((res) => {
                if (res.data.code === 200) {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Invite sent successfully.',
                            type: 'success',
                        },
                    });
                    resolve(true);
                } else {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Error in sending invite',
                            type: 'error',
                        },
                    });
                    reject(false);
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
            });
    });
};

export const createFormUsingTemplate = (data, id) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.createFormUsingTemplateApi}`,

                {
                    title: data.title,
                    shortUrl: data.shortUrl,
                    template_id: id,
                },
            )
            .then((res) => {
                if (res.data.code === 201) {
                    resolve(res.data.payload);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Form created successfully.',
                            type: 'success',
                        },
                    });
                } else if (res.data.code === 500) {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Please select tempalte.',
                            type: 'error',
                        },
                    });
                    store.dispatch({
                        type: 'START_LOADING',
                        payload: {
                            loading: false,
                        },
                    });
                } else {
                    reject(res.error);
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const CheckUrl = (shortUrl) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.checkUrlApi}`,

                {
                    shortUrl: shortUrl,
                },
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else if (res.data.code === 422) {
                        store.dispatch({
                            type: 'UPDATE_SNACK',
                            payload: {
                                snackbar: true,
                                message: 'Form url already exists.',
                                type: 'error',
                            },
                        });
                        store.dispatch({
                            type: 'START_LOADING',
                            payload: {
                                loading: false,
                            },
                        });
                        reject(res.error);
                    } else {
                        reject(res.error);
                    }
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const getFormSubmissionData = (id) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.getFormSubmissionDataApi}${id}`,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const updatePassword = (body) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.updateFormPass}`, body)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const getMailChimpAudienceList = (id) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.getmailChimpAudienceApi}${id}`)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getBulkMailChimpAudienceList = (id) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${
                    api.getmailChimpAudienceApi
                }${id}?source=invite`,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getMailChimpListDetail = (formId, listId) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${
                    api.getmailChimpListDetailApi
                }?formId=${formId}&listId=${listId}`,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
            })
            .catch((err) => {
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const getHubSpotContactProperty = (formId) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${
                    api.hubSpotContactPropertyApi
                }/${formId}`,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
            })
            .catch((err) => {});
    });
};

export const mailChimpIntegration = (credentials) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.mailChimpIntegration}`,
                credentials,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
                reject(res);
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response.data.error);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                } else {
                    reject(err);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Server error !! Please try again later',
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const hubSpotIntegration = (credentials) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.hubspotIntegration}`,
                credentials,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
                reject(res);
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response.data.error);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                } else {
                    reject(err);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Server error !! Please try again later',
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const gsheetIntegration = (credentials) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.gsheetIntegration}`,
                credentials,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
                reject(res);
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response.data.error);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                } else {
                    reject(err);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Server error !! Please try again later',
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const setMailChimpToken = (body) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.mailChimpTokenApi}`, body)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response.data.error);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                } else {
                    reject(err);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Server error !! Please try again later',
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const setHubSpotToken = (body) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.hubSpotTokenApi}`, body)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response.data.error);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                } else {
                    reject(err);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Server error !! Please try again later',
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const setGoogleSheetToken = (body) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.googlesheetTokenApi}`, body)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response.data.error);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                } else {
                    reject(err);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Server error !! Please try again later',
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const setGCalenderToken = (body) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.gcalenderTokenApi}`, body)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response.data.error);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                } else {
                    reject(err);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Server error !! Please try again later',
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const gcalenderIntegration = (credentials) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.gcalenderIntegration}`,
                credentials,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
                reject(res);
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response.data.error);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                } else {
                    reject(err);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Server error !! Please try again later',
                            type: 'error',
                        },
                    });
                }
            });
    });
};

// Salesforce

export const setSalesforceToken = (body) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.salesforceTokenApi}`, body)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response.data.error);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                } else {
                    reject(err);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Server error !! Please try again later',
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const getSalesforceProperty = (formId, propType) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${
                    api.salesforcePropertyApi
                }?formId=${formId}&propType=${propType}`,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const salesforceIntegration = (credentials) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.salesforceIntegration}`,
                credentials,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
                reject(res);
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response.data.error);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                } else {
                    reject(err);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Server error !! Please try again later',
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const formDuplication = (formId, dup_key, dup_val) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.formDuplicationApi}`, {
                formId: formId,
                dup_key: dup_key,
                dup_val: dup_val,
            })
            .then((res) => {
                resolve(res.data);
                if (res.status === 200) {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Save successfully.',
                            type: 'success',
                        },
                    });
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const getSmtpEmail = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.getEmailConfiguration}`)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const bulkEmailInvite = (credentials) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.bulkemailinvite}`,
                credentials,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        store.dispatch({
                            type: 'UPDATE_SNACK',
                            payload: {
                                snackbar: true,
                                message: 'Invite Sent Successfully',
                                type: 'success',
                            },
                        });
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
                reject(res);
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response.data.error);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                } else {
                    reject(err);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Server error !! Please try again later',
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const salesforceMembers = (formId) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.salesforceMember}/${formId}`)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
                reject(res);
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response.data.error);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                } else {
                    reject(err);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Server error !! Please try again later',
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const mailchimpMembers = (formId) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.mailchimlListMember}/${formId}`,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject('err');
                    }
                }
                reject(res);
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response.data.error);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                } else {
                    reject(err);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Server error !! Please try again later',
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const fetchWorkspace = (formId) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.workspaces}`)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code == 200) {
                        setWorkspaceDefaultId(res.data.payload);
                        resolve(res.data.payload);
                        localStorage.setItem('workspaces', JSON.stringify(res.data.payload));
                    } else {
                        reject('err');
                    }
                }
                reject(res);
            })
            .catch((err) => {
                if (err.response) {
                    reject(err.response.data.error);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                } else {
                    reject(err);
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Server Error !! Please try again later',
                            type: 'error',
                        },
                    });
                }
            });
    });
};

export const updateformScheduler = (data, id) => {
    if (!id) return;

    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.Updateformscheduler}`, {
                formId: id,
                formScheduler_status: data.formScheduler_status,
                formstart: data.formstart,
                formclose: data.formclose,
                formDescription: data.formDescription,
                limit: data.limit,
                limitmsg: data.limitmsg,
                schedulerstart: data.schedulerstart,
                schedulerclose: data.schedulerclose,
                schedulerlimit: data.schedulerlimit,
                schedulermsg: data.schedulermsg,
            })
            .then((res) => {
                resolve(res.data);
                if (res.status === 200) {
                    geteditForm(id).then((data) => {
                        store.dispatch({ type: 'UPDATE_CREATE_DATA', payload: data });
                    });
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};

export const addReviewerOnSubmission = (credentials) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.submissionReviewer}`,
                credentials,
            )
            .then((res) => {
                if (res.status === 200 && res.data?.code === 200) {
                    resolve(res.data.payload);
                } else {
                    reject(res);
                }
            })
            .catch((err) => {
                if (err.response && err.response?.data?.error) {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: err.response.data.error,
                            type: 'error',
                        },
                    });
                } else {
                    store.dispatch({
                        type: 'UPDATE_SNACK',
                        payload: {
                            snackbar: true,
                            message: 'Server error !! Please try again later',
                            type: 'error',
                        },
                    });
                }

                reject(err);
            });
    });
};

export const reorderForm = (startIndex, endIndex) => {
    const result = Array.from(store.getState().createForm.content);
    const [removed] = result.splice(startIndex, 1);

    if (!removed) return;
    result.splice(endIndex, 0, removed);
    store.dispatch({ type: 'UPDATE_CREATE_DATA', payload: { content: result } });
};

export const addNewItem = (id, dropIndex) => {
    const allItems = defaultWidgets();
    let item = allItems.find((i) => i.uuid == id);

    const elementOptions = create(item);

    const result = Array.from(store.getState().createForm.content);
    if (!elementOptions) return;

    result.splice(dropIndex, 0, elementOptions);
    store.dispatch({ type: 'UPDATE_CREATE_DATA', payload: { content: result } });
};

export const addNewPagebreak = (index) => {
    addNewItem('IFE03', index);
};

export const deleteElement = (id) => {
    const result = Array.from(store.getState().createForm.content);
    result.splice(
        result.findIndex((i) => i.id == id),
        1,
    );

    store.dispatch({ type: 'UPDATE_CREATE_DATA', payload: { content: result } });
};
