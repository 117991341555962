import api from 'api';
import axios from 'Utils/axios';

export const getUserIntegrationActivity = (pageNumber, pageLimit, search, sort) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.UserIntegrationActivity}?skip=${
                    (pageNumber - 1) * pageLimit
                }&limit=${pageLimit}&search=${search}&sort=${sort ? sort : '-activityDate'}`,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject(res.error);
                    }
                }
            })
            .catch((err) => {
                if (err.response) reject(err.response.data.error);
            });
    });
};
