import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IntelyForm from 'assets/images/intelyForms_Logos.png';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    divclass: {
        background: '#FFFFFF',
        boxShadow: '0px 1px 6px rgba(123, 133, 160, 0.25)',
        height: 60,
        paddingTop: 12,
        paddingLeft: 12,
        paddingBottom: 12,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 1000,
    },
    innerdiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
}));

const IFToolbarLoginSignUp = () => {
    const classes = useStyles();

    return (
        <Toolbar className={classes.divclass}>
            <img
                alt=""
                style={{ cursor: 'pointer' }}
                src={IntelyForm}
                onClick={() => {
                    localStorage.clear();
                    window.location.replace('/');
                }}
            />
            <div className={classes.innerdiv}></div>
        </Toolbar>
    );
};

export default IFToolbarLoginSignUp;
