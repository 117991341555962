import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const add = (props) => {
    return (
        <SvgIcon width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.9727 3.44531L10.0195 0.527344C9.70312 0.210938 9.28125 0 8.85938 0H1.65234C0.738281 0.0351562 0 0.773438 0 1.72266V16.3477C0 17.2617 0.738281 18 1.65234 18H11.7773C12.7266 18 13.5 17.2617 13.5 16.3477V4.64062C13.5 4.21875 13.2891 3.76172 12.9727 3.44531ZM8.96484 1.82812L11.6719 4.53516H8.96484V1.82812ZM11.8125 16.3477H1.65234V1.72266H7.27734V5.37891C7.27734 5.83594 7.66406 6.22266 8.12109 6.22266H11.8125V16.3477ZM6.39844 8.01562L3.86719 10.5469C3.62109 10.8281 3.79688 11.2852 4.18359 11.2852H5.90625V14.2383C5.90625 14.4492 6.08203 14.6602 6.32812 14.6602H7.17188C7.38281 14.6602 7.59375 14.4492 7.59375 14.2383V11.2852H9.28125C9.66797 11.2852 9.84375 10.8281 9.59766 10.5469L7.06641 8.01562C6.89062 7.83984 6.57422 7.83984 6.39844 8.01562Z"
                fill="#7D8995"
            />
        </SvgIcon>
    );
};
export default add;
