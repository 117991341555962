import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const add = (props) => {
    return (
        <SvgIcon width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.7414 0.808594L13.4367 0.0703125C13.2961 0.0351562 13.1906 0 13.0852 0C12.4523 0 11.8547 0.386719 11.6086 0.984375L10.0617 4.57031C9.78047 5.20312 9.95625 5.97656 10.5188 6.43359L11.925 7.59375C10.9406 9.42188 9.39375 10.9336 7.56563 11.9531L6.40547 10.5469C6.08906 10.1602 5.63203 9.94922 5.175 9.94922C4.96406 9.94922 4.75313 9.98438 4.54219 10.0898L0.956252 11.6016C0.253127 11.918 -0.133592 12.6914 0.0421892 13.4648L0.78047 16.7695C0.956252 17.5078 1.62422 18 2.3625 18C10.9758 18 18.007 11.0391 18.007 2.39062C18.007 1.65234 17.4797 0.984375 16.7414 0.808594ZM2.43281 16.3125L1.69453 13.1484L5.13985 11.6719L7.1086 14.0625C10.5891 12.4102 12.382 10.6172 14.0344 7.13672L11.6438 5.16797L13.1203 1.72266L16.3195 2.46094C16.2844 10.0898 10.0617 16.2773 2.43281 16.3125ZM3.10078 7.3125C3.10078 7.62891 3.34688 7.875 3.66328 7.875H4.22578C4.50703 7.875 4.78828 7.62891 4.78828 7.3125V4.78125H7.31953C7.60078 4.78125 7.88203 4.53516 7.88203 4.21875V3.65625C7.88203 3.375 7.60078 3.09375 7.31953 3.09375H4.78828V0.5625C4.78828 0.28125 4.50703 0 4.22578 0H3.66328C3.34688 0 3.10078 0.28125 3.10078 0.5625V3.09375H0.569533C0.253127 3.09375 0.00703296 3.375 0.00703296 3.65625V4.21875C0.00703296 4.53516 0.253127 4.78125 0.569533 4.78125H3.10078V7.3125Z"
                fill="#7D8995"
            />
        </SvgIcon>
    );
};
export default add;
