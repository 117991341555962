import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { updateFeedBack } from 'redux/action/utils.action';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles1 = (theme) => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

function MySnackbarContent(props) {
    const { classes, className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node.isRequired,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

MySnackbarContent.defaultProps = {
    onClose: () => {},
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles = (theme) => ({
    snackbar: {
        margin: theme.spacing(1),
    },
    divider: {
        display: 'block',
        margin: `${theme.spacing(3)}px 0`,
    },
    margin: {
        margin: theme.spacing(1),
    },
});

class StyledNotif extends React.Component {
    state = {
        openStyle: false,
    };

    handleClickStyle = () => {
        this.setState({ openStyle: true });
    };

    handleCloseStyle = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ openStyle: false });
    };

    render() {
        return (
            <Snackbar
                style={{ zIndex: 2000 }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.props.snackbar}
                autoHideDuration={6000}
                onClose={() => this.props.updateFeedBack({ snackbar: false })}
            >
                <MySnackbarContentWrapper
                    onClose={() => this.props.updateFeedBack({ snackbar: false })}
                    variant={this.props.type}
                    message={this.props.message}
                />
            </Snackbar>
        );
    }
}

StyledNotif.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        ...state.feedback,
    };
};

export default connect(mapStateToProps, { updateFeedBack })(withStyles(styles)(StyledNotif));
