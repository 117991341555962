import axios from 'axios';
import { logout } from './loginUtils';
// Next we make an 'instance' of it

const instance = axios.create({
    // .. where we make our configurations
    baseURL: '',
});

instance.defaults.withCredentials = true;

// Where you would set stuff like your 'Authorization' header, etc ...
instance.defaults.headers.common['Authorization'] = localStorage.getItem('token');

instance.defaults.headers.common['workspaceId'] = localStorage.getItem('workspaceId');

// instance.defaults.headers.common['Mrn']=localStorage.getItem("mrn")
//instance.defaults.headers.common["Access-Control-Max-Age"]=600
// Also add/ configure interceptors && all the other cool stuff
const isHandlerEnabled = (config = {}) => {
    return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ? false : true;
};

const errorHandlerResponse = (error) => {
    if (isHandlerEnabled(error.config)) {
        if (error.response && (error.response.status === 401 || error.response.status === 411)) {
            setTimeout(() => logout(), 1000);
        }
    }
    return Promise.reject({ ...error });
};

const successHandler = (response) => {
    if (isHandlerEnabled(response.config)) {
        // Handle responses
    }
    return response;
};

instance.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandlerResponse(error),
);

export default instance;
