import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const add = (props) => {
    return (
        <SvgIcon width="16" height="16" {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 9.5V1.5C11 0.6875 10.3125 0 9.5 0H1.5C0.65625 0 0 0.6875 0 1.5V9.5C0 10.3438 0.65625 11 1.5 11H9.5C10.3125 11 11 10.3438 11 9.5ZM1.5 1.5H9.5V9.5H1.5V1.5ZM14.5 5H12V6.5H14.5V14.5H6.5V12H5V14.5C5 15.3438 5.65625 16 6.5 16H14.5C15.3125 16 16 15.3438 16 14.5V6.5C16 5.6875 15.3125 5 14.5 5ZM7.5 13C7.5 13.2812 7.71875 13.5 8 13.5H13C13.25 13.5 13.5 13.2812 13.5 13V8C13.5 7.75 13.25 7.5 13 7.5H12V12H7.5V13Z" />
        </SvgIcon>
    );
};
export default add;
