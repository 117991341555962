import axios from 'Utils/axios';
import store from 'redux/store';
import api from 'api';

export const getUserLoginActivity = (pageNumber, pageLimit, search, sort) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST + '/forms/'}${api.UserLoginActivity}?skip=${
                    (pageNumber - 1) * pageLimit
                }&limit=${pageLimit}&search=${search}&sort=${sort ? sort : '-activityDate'}`,
            )
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        resolve(res.data.payload);
                    } else {
                        reject(res.error);
                    }
                }
            })
            .catch((err) => {
                reject(err.response.data.error);
                store.dispatch({
                    type: 'UPDATE_SNACK',
                    payload: {
                        snackbar: true,
                        message: err.response.data.error,
                        type: 'error',
                    },
                });
            });
    });
};
