const api = {
    // LoginPage EndPoint

    userlogin: 'user/login',
    usersignup: 'user/signup',

    ssologin: 'user/sso-login',

    resendCodeApi: '/user/resend-mfa-code',

    //Create Form EndPoint/

    //update form
    submitFormDataApi: 'form/update/',

    //get All form
    getAllFormApi: 'form/all',

    //Create form
    createNewFormApi: 'form/add',

    //Delete form
    getdeleteFormApi: 'form/delete',

    //disabled form
    disabledFormApi: 'form/disable',

    //Get perticular form details
    geteditFormApi: 'form/',

    //Get delete form
    getAllDeletedApi: 'form/get-deleted',

    //Get all archived
    markedAsArchivedApi: 'form/archived/add',

    //get all archived
    getAllArchivedApi: 'form/archived/get',

    //restore form
    recoverFormApi: 'form/restore/',

    //copy form
    copyFormApi: 'form/copy/',

    //add to favorite
    addToFavoriteApi: 'form/favourite/add',

    //get all favorite
    getAllFavoriteApi: 'form/favourite/get',

    //remove from the favorite
    removeFromTheFavoriteApi: 'form/favourite/remove/',

    //unarchive
    UnArchiveApi: 'form/archived/remove/',

    //create form using template
    createFormUsingTemplateApi: 'form/by-template',

    //check url
    checkUrlApi: 'form/check-url',

    //get form submission data
    getFormSubmissionDataApi: 'submission/form/',

    //Templates API

    //create template
    createTemplateApi: 'template/add',

    //get all template
    getAllTemplateApi: 'template/all',

    //update template
    updateTemplateApi: 'template/update/',

    //delete template
    deleteTemplateApi: 'template/delete',

    //get all deleted template
    getAllDeletedTemplateApi: 'template/get-deleted',

    //restore template
    restoreTemplateApi: 'template/restore/',

    //Get perticular template details
    geteditTemplateApi: 'template/',

    //disable template
    disabledTemplateApi: 'template/disable',

    //get template by category
    getTemplateByCategoryApi: 'template/by-category',

    //get template category count
    getTemplateCategoryCountApi: 'template/category-count',

    //get template category
    getTemplateCategoryApi: 'category/all',

    presignedUrl: 'user/presigned-url',

    //createCategoryApi
    createCategoryApi: 'category/add',

    //updateCategotyApi
    updateCategotyApi: 'category/update/',

    //deleteCategory
    deleteCategoryApi: 'category/delete',

    //disabledCateogry
    disabledCategoryApi: 'category/disable',

    //send invite to forms
    sendInvite: 'user/send-form-invite',
    updateFormPass: 'form/form-password',
    //submit form
    submitForm: 'submission/add',
    addRating: 'submission/update-rating',

    //  forget password send code

    forgetPassSendCode: 'user/forgot-pass',

    // in Edit profile Account info

    getAccountInformation: 'user/get',

    //update profile Account
    updateUserAccount: 'user/update/',

    //  update email configration
    addEmailConfiguration: 'user/smtp/add',

    updateEmailConfiguration: 'user/smtp/update',

    deleteEmailConfiguration: 'user/smtp/delete',

    getEmailConfiguration: 'user/smtp/get',

    //form duplication Api
    formDuplicationApi: 'form/duplicate',

    getSmtpEmailApi: 'user/user-email',

    ResendVerificationLink: 'user/resend-verification-link',

    forgotResendCode: 'user/forgot-resend-code',

    registerUser: 'user/register-user',

    appSumoRegisterUser: 'user/register-appsumo-user',

    forgotChangePass: 'user/forgot-change-pass',

    updatePass: 'user/update-pass',

    mailChimpTokenApi: 'form/integration/mailchimp/config',

    getmailChimpAudienceApi: 'form/integration/mailchimp/audience/',

    getmailChimpListDetailApi: 'form/integration/mailchimp/list-detail',

    mailChimpIntegration: 'form/integration/mailchimp/integration',

    removeIntegration: 'form/integration/remove',

    googlesheetTokenApi: 'form/integration/gsheet/config',

    gsheetIntegration: 'form/integration/gsheet/integration',

    gcalenderTokenApi: 'form/integration/gcalender/config',

    gcalenderIntegration: 'form/integration/gcalender/integration',

    hubSpotTokenApi: 'form/integration/hubspot/config',

    hubSpotContactPropertyApi: 'form/integration/hubspot/property',

    hubspotIntegration: 'form/integration/hubspot/integration',

    salesforceTokenApi: 'form/integration/salesforce/config',

    salesforcePropertyApi: 'form/integration/salesforce/property',

    salesforceIntegration: 'form/integration/salesforce/integration',

    zohoTokenApi: 'form/integration/zoho/config',

    zohoPropertyApi: 'form/integration/zoho/property',

    zohoIntegrationApi: 'form/integration/zoho/integration',

    userVerifyEmail: 'user/verify-email',
    // login Activity
    UserLoginActivity: 'user/login/get-activity',

    bulkemailinvite: 'user/send-email-invite',

    getAllAppointments: 'form/appointment',

    // user integration activity
    UserIntegrationActivity: 'user/integration/get-activity',

    //get payment session
    GetPaymentSession: 'user/payment-session',

    //add seats
    AddSeats: 'user/add-seat',

    //upgrade Subscription
    UpgradeSubscriptions: 'user/change-subscription',

    //demo
    LoginWithPayment: 'user/payment-session/token',

    //customerid
    CustomerIdPortal: 'user/customer-portal',

    errorboundary: 'user/app-error',

    salesforceMember: 'form/integration/salesforce/member',

    mailchimlListMember: 'form/integration/mailchimp/list/member',

    //getsessionplan
    getsessionplan: 'subscription/get',

    //updateuserorganization
    UpdateUserOrg: 'user/update/organization',
    // white label
    whiteLabel: 'user/update-whitelabel',

    //check-plan
    CheckPlan: 'planUsage/check',
    // update form scheduler
    Updateformscheduler: 'form/form-scheduler',

    //get workspace
    workspaces: 'workspace/all',

    //create workspace

    createWorkspaces: 'workspace/add',

    //invite workspace member

    InviteWorkspaceMember: 'workspace/invite',

    //get workspace member

    getWorkspaceMember: 'workspace/get-member',

    //enable disable workspace
    enableDisableWorkspace: 'workspace/disable',

    //update workspace

    updateWorkspace: 'workspace/update',

    //delete workspace

    deleteWorkspace: 'workspace/delete',

    //resend workspace invite
    resendWorkspaceInvite: 'workspace/reinvite',

    //update workspace member
    updateWorkspaceMember: 'workspace/update-role',

    //cancel member request
    cancelMemberRequest: 'workspace/cancel-request',

    //remove workspace member
    removeWorkspaceMember: 'workspace/remove-member',

    verifyWorkspaceInvite: 'workspace/verify-invite',

    registerWorkspaceMemeber: 'workspace/register-member',

    //get organization workspace
    getOrganizationWorkspace: 'workspace/get-organization-workspace',

    //update user workspace permission
    updateUserWorkspacePermission: 'workspace/update-permission',
    // white label
    getWhiteLabel: 'user/whitelabel/get',
    addWhiteLabel: 'user/whitelabel/add',
    updateWhiteLabel: 'user/whitelabel/update',
    deleteWhiteLabel: 'user/whitelabel/delete',

    getplanusage: 'planUsage/get',

    //addApiKey
    addApiKey: 'api-key/add',

    //getApiKey
    getApiKey: 'api-key/get',

    //deleteApiKey
    deleteApiKey: 'api-key/delete',

    //updateApiKey
    updateApiKey: 'api-key/update',

    //Add Webhook
    addWebhook: 'api-key/webhook/add',

    //Update Webhook
    updateWebhook: 'api-key/webhook/update',

    //Get Webhook
    getWebhook: 'api-key/webhook/get',

    //Disable-Enable Webhook
    disableEnableWebhook: 'api-key/webhook/disable-enable',

    //DeleteWebhook
    deleteWebhook: 'api-key/webhook/delete',

    //getting forms for webhook
    getallforms: 'user/all-forms',

    //Get Webhook Logs
    getWebhookLogs: 'api-key/webhook/log',

    // Send Test Event
    sendTestEvent: 'api-key/webhook/event/test',

    //Resend Webhook Log
    resendWebhookLog: 'api-key/webhook/log/resend',

    //Add Form Prod
    addFormProd: 'form/stripe-checkout/product/add',

    //Get Form Prod
    getFormProd: 'form/stripe-checkout/product',

    //Update Fom Prod
    updateFormProd: 'form/stripe-checkout/product/update',

    //Delete Form Prod
    deleteFormProd: 'form/stripe-checkout/product/delete',

    //Stripe Checkout Connect
    stripeCheckoutConnect: 'form/stripe-checkout/connect',

    //Stripe Checkout Disconnect
    stripeCheckoutDisconnect: 'form/stripe-checkout/disconnect',

    //Stripe Checkout Session
    stripeCheckoutSession: 'form/stripe-checkout/checkout-session',

    //getAllOrgName
    getAllOrgName: 'user/organization/all',
    // Add Submission reviewer
    submissionReviewer: 'submission/update-review',

    //Upload Public File
    uploadPublicFile: 'user/file-upload/public',

    //Fetch Form With Pass
    fetchFormWithPassword: 'form/link-form',

    getUserOrganizations: '/user/{userId}/organizations',
    //Add personnel
    addPersonnel: 'personnel/insert',

    //get personnel info by id
    getPersonnelById: 'personnel',

    //Update personnel
    updatePersonnel: 'personnel',

    //Delete personnel
    deletePersonnel: 'personnel/delete',

    //get all personnel
    getAllPersonnel: 'personnel/all',

    //Add location
    addLocation: 'location/insert',

    //get location info by id
    getLocationById: 'location',

    //Update location
    updateLocation: 'location',

    //Delete Location
    deleteLocation: 'location/delete',

    //get all Location
    getAllLocation: 'location/all',

    //Add appointment block
    addAppointmentBlock: 'appointmentBlock/insert',

    //get Appointment Block info by id
    getAppointmentBlockById: 'appointmentBlock',

    //Update Appointment Block
    updateAppointmentBlock: 'appointmentBlock',

    //Delete appointment Block
    deleteAppointmentBlock: 'appointmentBlock/delete',

    //get all block appointment
    getAllAppointmentBlock: 'appointmentBlock/all',

    //Add calendar
    addCalendar: 'calendar/insert',

    //get calendar info by id
    getCalendarById: 'calendar',

    //Update calendar
    updateCalendar: 'calendar',

    //Delete calendar
    deleteCalendar: 'calendar/delete',

    //get all calendar
    getCalanderByPersonIdLocID: 'calendar/byPersonnelLocation',
    getPersonnelByLocationId: 'personnel/byLocation',
    getLocationByFormId: 'location/byForm',
    getAllCalendar: 'calendar/all',
    getBookedSlots: 'calendar/bookedSlot',
    getBlockAppointmentByPersonnelId: 'appointmentBlock/byPersonnelLocation',
};

export default api;
