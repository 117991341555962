import React from 'react';

import ErrorPage from '../ErrorPage';

class FormsErrorBoundary extends React.Component {
    state = { error: null, errorInfo: null };

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    render() {
        if (this.state.errorInfo) {
            return <ErrorPage />;
        }

        // eslint-disable-next-line
        return this.props.children;
    }
}

export default FormsErrorBoundary;
