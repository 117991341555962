import React from 'react';
import { Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    Cardclass: {
        borderRadius: 15,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '260px 102rem',
        width: '260px',
        height: '102rem',
        boxShadow: '0px 12px 18px -6px rgb(0 0 0 / 30%)',
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
        height: '6rem',
    },
    Cardclassmain: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '260px 102rem',
        width: '260px',
        height: '102rem',
    },

    flexItem: {
        marginTop: '1rem',
        textAlign: 'center',
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
    },
    divider: {
        height: 2,
        background: '#f0f0f0',
        margin: 20,
    },
    divider1: {
        height: 2,
        background: '#f0f0f0',
        marginLeft: 20,
        marginRight: 20,
    },
    contentbox: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: '192px 175px',
        width: '192px',
        height: '175px',
    },
    typoclass: {
        fontSize: '16px',
        color: '#666',
        paddingBottom: '10px',
        fontWeight: '500',
    },
}));
const PackagesSection = (props) => {
    const classes = useStyles();
    return (
        <li className={classes.flexItem}>
            <Box component="div" className={classes.Cardclassmain}>
                <Box component="div" className={classes.Cardclass}>
                    <Box component="div" className={classes.cardHeader}>
                        <Typography
                            style={{
                                textAlign: 'center',
                                fontSize: 26,
                                fontWeight: 'bold',
                            }}
                        >
                            Packages
                        </Typography>
                    </Box>
                    <Box component="div" className={classes.cardPricing} />
                    <Divider classes={{ root: classes.divider }} />
                    <Box component="div" style={{ textAlign: 'left', padding: 10 }}>
                        <Typography className={classes.typoclass}># of Form</Typography>
                        <Typography className={classes.typoclass}># of Submissions</Typography>
                        <Typography className={classes.typoclass}># of Workspaces</Typography>
                        <Typography className={classes.typoclass}>Number of Users</Typography>
                    </Box>
                    <Divider classes={{ root: classes.divider1 }} />
                    <Box component="div" style={{ textAlign: 'left', padding: 10 }}>
                        <Typography style={{ fontWeight: 'bolder', paddingBottom: '10px' }}>Core Features</Typography>
                        <Typography className={classes.typoclass}>Conditional Logic</Typography>
                        <Typography className={classes.typoclass}>Automated Emails & Invites</Typography>
                        <Typography className={classes.typoclass}>Submission Notifications</Typography>
                        <Typography className={classes.typoclass}>Social Sharing</Typography>
                        <Typography className={classes.typoclass}>Form Template Library</Typography>
                        <Typography className={classes.typoclass}>File & Photo Uploads</Typography>
                    </Box>
                    <Divider classes={{ root: classes.divider1 }} />
                    <Box component="div" style={{ textAlign: 'left', padding: 10 }}>
                        <Typography style={{ fontWeight: 'bolder', paddingBottom: '10px' }}>
                            Advanced Features
                        </Typography>
                        <Typography className={classes.typoclass}>Google Analytics</Typography>
                        <Typography className={classes.typoclass}>API & Webhook Access</Typography>
                        <Typography className={classes.typoclass}>Custom Branding & Embedding </Typography>
                        <Typography className={classes.typoclass}>Industry Specific Form Fields</Typography>
                        <Typography className={classes.typoclass}>Custom Subdomain</Typography>
                        <Typography className={classes.typoclass}>Remove intely Branding</Typography>
                        <Typography className={classes.typoclass}>Payment Integration</Typography>
                        <Typography className={classes.typoclass}>Visual Workflow Builder</Typography>
                    </Box>
                    <Divider classes={{ root: classes.divider1 }} />
                    <Box component="div" style={{ textAlign: 'left', padding: 10 }}>
                        <Typography style={{ fontWeight: 'bolder', paddingBottom: '10px' }}>
                            Automation & Integration
                        </Typography>
                        <Typography className={classes.typoclass}>Basic Integrations</Typography>
                        <Typography className={classes.typoclass}>Zapier Integration</Typography>
                        <Typography className={classes.typoclass}>Premium Integrations </Typography>
                        <Typography className={classes.typoclass}>Enterprise Integrations</Typography>
                        <Typography className={classes.typoclass}>Healthcare Integration (FHIR, API & HL7)</Typography>
                        <Typography className={classes.typoclass}>On-Premise & VPN Connectivity</Typography>
                    </Box>
                    <Divider classes={{ root: classes.divider1 }} />
                    <Box component="div" style={{ textAlign: 'left', padding: 10 }}>
                        <Typography style={{ fontWeight: 'bolder', paddingBottom: '10px' }}>
                            Account & Security
                        </Typography>
                        <Typography className={classes.typoclass}>HIPAA Compliance</Typography>
                        <Typography className={classes.typoclass}>Enterprise SSO</Typography>
                        <Typography className={classes.typoclass}>Authentication & Vendor Management</Typography>
                    </Box>
                    <Divider classes={{ root: classes.divider1 }} />
                    <Box component="div" style={{ textAlign: 'left', padding: 10 }}>
                        <Typography style={{ fontWeight: 'bolder', paddingBottom: '10px' }}>Support</Typography>
                        <Typography className={classes.typoclass}>Ticket Support Center</Typography>
                        <Typography className={classes.typoclass}>Deployment & Rollout Support</Typography>
                        <Typography className={classes.typoclass}>Dedicated CSM & Signed SLA</Typography>
                    </Box>
                </Box>
            </Box>
        </li>
    );
};

export default PackagesSection;
