import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const add = (props) => {
    return (
        <SvgIcon width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.55612 6.97778C4.74596 6.97778 4.88834 7.01852 4.98326 7.1C5.07818 7.18148 5.12564 7.2963 5.12564 7.44444C5.12564 7.76296 4.9358 7.92222 4.55612 7.92222H0.593245C0.205658 7.92222 0.0118649 7.76296 0.0118649 7.44444C0.0118649 7.2963 0.0593245 7.18148 0.154244 7.1C0.257073 7.01852 0.403407 6.97778 0.593245 6.97778H1.95771V1.43333L0.735624 2.15556C0.656524 2.2 0.577425 2.22222 0.498326 2.22222C0.363857 2.22222 0.245208 2.16667 0.142379 2.05556C0.0474596 1.94444 0 1.81852 0 1.67778C0 1.50741 0.0790993 1.37407 0.237298 1.27778L2.06449 0.188889C2.24642 0.0851854 2.42044 0.0333336 2.58655 0.0333336C2.76057 0.0333336 2.90295 0.0851854 3.01368 0.188889C3.12442 0.292593 3.17979 0.433333 3.17979 0.611111V6.97778H4.55612Z"
                fill="#7D8995"
            />
            <path
                d="M11.7535 6.97778C11.9433 6.97778 12.0857 7.01852 12.1806 7.1C12.2834 7.18148 12.3349 7.2963 12.3349 7.44444C12.3349 7.76296 12.1411 7.92222 11.7535 7.92222H7.17363C6.99961 7.92222 6.86119 7.87778 6.75836 7.78889C6.66344 7.69259 6.61598 7.57407 6.61598 7.43333C6.61598 7.26296 6.68717 7.10741 6.82955 6.96667L9.6178 4.11111C9.98956 3.73333 10.2585 3.38889 10.4246 3.07778C10.5907 2.76667 10.6738 2.45926 10.6738 2.15556C10.6738 1.76296 10.5512 1.46296 10.306 1.25556C10.0687 1.04074 9.72854 0.933334 9.28558 0.933334C8.61324 0.933334 7.94485 1.17407 7.28041 1.65556C7.1934 1.70741 7.11826 1.74815 7.05498 1.77778C6.99961 1.8 6.94029 1.81111 6.87701 1.81111C6.76627 1.81111 6.67135 1.76296 6.59225 1.66667C6.51315 1.57037 6.4736 1.45556 6.4736 1.32222C6.4736 1.13704 6.5527 0.988889 6.7109 0.877778C7.04311 0.611112 7.44257 0.4 7.90925 0.244445C8.37594 0.0814815 8.84658 0 9.32118 0C10.1043 0 10.7252 0.188889 11.184 0.566667C11.6507 0.937037 11.884 1.44074 11.884 2.07778C11.884 2.52963 11.7772 2.95926 11.5636 3.36667C11.358 3.77407 11.0099 4.22963 10.5195 4.73333L8.27706 6.97778H11.7535Z"
                fill="#7D8995"
            />
            <path
                d="M17.6576 3.9C18.1638 4.03333 18.5474 4.26296 18.8085 4.58889C19.0695 4.90741 19.2 5.31111 19.2 5.8C19.2 6.48148 18.9429 7.01852 18.4288 7.41111C17.9225 7.8037 17.2344 8 16.3643 8C15.8501 8 15.3558 7.92222 14.8812 7.76667C14.4145 7.61111 14.019 7.3963 13.6947 7.12222C13.5365 7.0037 13.4574 6.85556 13.4574 6.67778C13.4574 6.54444 13.4969 6.43333 13.576 6.34444C13.6551 6.24815 13.7501 6.2 13.8608 6.2C13.9794 6.2 14.1139 6.24815 14.2642 6.34444C14.6043 6.58148 14.9365 6.76296 15.2609 6.88889C15.5931 7.00741 15.949 7.06667 16.3287 7.06667C17.4519 7.06667 18.0135 6.61852 18.0135 5.72222C18.0135 5.28518 17.8632 4.95926 17.5626 4.74444C17.27 4.52222 16.8231 4.41111 16.2219 4.41111H15.5693C15.4191 4.41111 15.3004 4.36667 15.2134 4.27778C15.1264 4.18148 15.0829 4.07037 15.0829 3.94444C15.0829 3.82593 15.1264 3.72222 15.2134 3.63333C15.3004 3.53704 15.4191 3.48889 15.5693 3.48889H16.0321C16.6095 3.48889 17.0485 3.37778 17.3491 3.15556C17.6576 2.92593 17.8118 2.59259 17.8118 2.15556C17.8118 1.77037 17.6853 1.47037 17.4321 1.25556C17.1869 1.04074 16.8389 0.933334 16.388 0.933334C15.7236 0.933334 15.0592 1.17407 14.3947 1.65556C14.2444 1.75185 14.11 1.8 13.9913 1.8C13.8806 1.8 13.7857 1.75556 13.7066 1.66667C13.6275 1.57037 13.5879 1.45556 13.5879 1.32222C13.5879 1.14444 13.667 0.996297 13.8252 0.877778C14.1495 0.603704 14.5411 0.388889 14.9998 0.233334C15.4665 0.0777779 15.9451 0 16.4355 0C17.2186 0 17.8434 0.185185 18.3101 0.555556C18.7768 0.925926 19.0062 1.42222 18.9983 2.04444C18.9983 2.47407 18.8796 2.85556 18.6424 3.18889C18.4051 3.51481 18.0768 3.75185 17.6576 3.9Z"
                fill="#7D8995"
            />
            <path d="M0 10H19V11H0V10Z" fill="#7D8995" />
        </SvgIcon>
    );
};
export default add;
