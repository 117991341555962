const INIT_STATE = {
    products: [],
    prodIds: [],
    prodQnty: [],
};

const stripeCheckout = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'STRIPE_PRODUCTS':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default stripeCheckout;
