const INIT_STATE = {
    searchingText: '',
    previewModeSubject: 1,
    previewPropsModeSubject: undefined,
    bannerImageUrl: '',
    uploadImageObject: {},
};

const GlobalSearch = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SEARCHING':
            return { ...state, ...action.payload };
        case 'PREVIEW_MODE_SUBJECT':
            return { ...state, ...action.payload };
        case 'PREVIEW_PROPS_MODE_SUBJECT':
            return { ...state, ...action.payload };
        case 'SET_GLOBAL_SEARCH_STATE':
            return { ...state, ...action.payload };
        case 'SET_UPLOAD_FILES':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default GlobalSearch;
