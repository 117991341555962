import { createTheme } from '@material-ui/core/styles';

// Create a theme instance.

const CircularStd = {
    fontFamily: 'CircularStd',
    fontStyle: 'normal',

    src: ` local('CircularStd'), url(/fonts/CircularStd-Bold.woff2) format('woff2')`,
};
const CircularStdBold = {
    fontFamily: 'CircularStd',
    fontStyle: 'bold',

    src: ` local('CircularStd'), url(/fonts/CircularStd-Bold.woff2) format('woff2')`,
};
const Circularmedium = {
    fontFamily: 'CircularStd',
    fontStyle: 'medium',

    src: ` local('CircularStd'), url(/fonts/CircularStd-Medium.woff2) format('woff2')`,
};
export const lightTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 720,
            md: 1024,
            lg: 1280,
            xl: 1580,
        },
    },
    palette: {
        primary: {
            main: '#3A70D4',
            light: '#EFEBFE',
            contrastText: '#fff',
            dark: '#4f4f4f',
        },
        secondary: {
            main: '#3d3d3d',
            light: '#ccc4df',
            contrastText: '#ffffff',
        },
        text: {
            primary: '#0C071C',
            secondary: '#6D6A77',
        },
        background: {
            default: '#FAF9FD',
            paper: '#fff',
        },
        divider: '#ededed',
    },

    typography: {
        fontFamily: 'CircularStd',
        h6: {
            textTransform: 'capitalize',
        },
        subtitle1: {
            '&:first-letter': {
                textTransform: 'capitalize',
            },
        },
        body1: {
            '&:first-letter': {
                textTransform: 'capitalize',
            },
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [CircularStd, CircularStdBold, Circularmedium],
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: '#FFFFFF',
            },
            rounded: {
                borderRadius: 8,
            },
            elevation1: {
                boxShadow:
                    '0px 1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)',
            },
            elevation4: {
                boxShadow:
                    '0px 2px 4px -1px rgba(142, 142, 142, 0.2), 0px 4px 5px 0px rgba(243, 243, 243, 0.14), 0px 1px 10px 0px rgba(204, 204, 204, 0.12)',
            },
        },
        MuiTypography: {
            button: {
                fontStyle: 'normal',
                fontWeight: 400,
                textTransform: 'capitalize',
                fontSize: 16,
            },
            colorTextPrimary: {
                color: '#0C071C',
            },
            colorTextSecondary: {
                color: '#6D6A77',
            },
        },
        MuiOutlinedInput: {
            root: {
                border: '1px solid #D7DCEA',
                borderRadius: 5,
                background: '#F9F9F9',
                paddingLeft: 8,

                height: 48,
                overflow: 'hidden',
                alignItems: 'center',
            },
            multiline: {
                minHeight: 48,
                height: 'auto',
            },
            marginDense: {
                height: '40px',
            },
            inputMarginDense: {
                height: '40px',
            },
        },
        MuiInput: {
            inputMarginDense: {
                height: 40,
            },
            marginDense: {
                height: '40px',
            },
        },
        MuiButton: {
            contained: {
                backgroundColor: '#f0f0f0',
                boxShadow: 'none',
                color: '#6437F4',
            },
            root: {
                borderRadius: 5,
                padding: 8,
                paddingLeft: 24,
                paddingRight: 24,
                fontSize: 16,

                letterSpacing: '0.01em',
            },
            sizeSmall: {
                padding: '7px 12px',
            },
        },
        MuiContainer: {
            root: {
                padding: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                borderRadius: 12,
                '@media (min-width: 1600px)': {
                    padding: 0,
                    paddingLeft: 32,
                    paddingRight: 32,
                    marginTop: 16,
                },
                '@media (min-width: 600px)': {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
            },
            maxWidthLg: {
                marginTop: 0,
                padding: 0,
                paddingLeft: 0,
                paddingRight: 0,
            },
            maxWidthXl: {
                marginTop: 0,
                paddingLeft: 0,
            },
        },

        MuiFormLabel: {
            root: {
                fontSize: 14,
            },
        },
        MuiFormHelperText: {
            root: {
                paddingLeft: 5,
            },
        },
        MuiSelect: {
            root: {
                borderRadius: 8,
            },
            icon: {
                top: 'calc(50% - 10px)',
                right: 0,
            },
            selectMenu: {
                paddingRight: '24px',
            },
        },

        MuiToolbar: {
            root: {
                borderRadius: 8,
            },
        },

        MuiListItemText: {
            root: {
                whiteSpace: 'nowrap',
            },
        },
        MuiLinearProgress: {
            root: {
                borderRadius: 16,
            },
            bar: {
                borderRadius: 16,
            },
        },
        MuiTablePagination: {
            input: {
                marginRight: 32,
                marginLeft: 8,
            },
            selectRoot: {
                marginLeft: 0,
                marginRight: 0,
            },
            select: {
                paddingRight: 24,
            },
            selectIcon: {
                top: 4,
            },
        },
        MuiTableCell: {
            head: {
                fontSize: '16px !important',
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                borderRadius: 0,
                boxShadow: 'inset 0 -30px 120px -30px rgba(0, 0, 0, 0.3)',
            },
        },

        MuiExpansionPanel: {
            root: {
                '&:first-child': {
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                },
                '&:last-child': {
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                },
                '&$expanded': {
                    borderRadius: 8,

                    '& + div': {
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                    },
                },
            },
        },
        MuiDialogTitle: {
            root: {
                position: 'relative',
                marginBottom: 32,
                '&:after': {
                    content: '""',
                    position: 'absolute',
                    width: 60,
                    height: 4,

                    bottom: 0,
                    left: 26,
                },
            },
        },
        MuiSnackbarContent: {
            root: {
                '@media (min-width: 960px)': {
                    borderRadius: 8,
                },
            },
        },
        MuiAppBar: {
            root: {
                boxShadow: 'none',
            },
        },
        MuiTabs: {
            root: {
                borderRadius: 10,
            },
            indicator: {
                borderRadius: '10px 10px 0 0',
                height: 4,
            },
        },

        MUIDataTableToolbar: {
            filterPaper: {
                maxWidth: 'none',
            },
        },

        MUIDataTable: {
            root: {
                backgroundColor: 'rgba(0,0,0,0)',
                overflow: 'hidden',
                borderRadius: 15,
                border: '1px solid rgba(2, 23, 34, 0.1)',
            },
            paper: {
                boxShadow: 'none',
                backgroundColor: 'rgba(0,0,0,0)',
                border: '1px solid rgba(2, 23, 34, 0.1)',
            },
        },
        MUIDataTableBody: {
            root: {
                borderBottom: '1px solid rgba(2, 23, 34, 0.1)',
            },
        },

        MuiTableFooter: {
            root: {
                borderRadius: 15,

                borderTop: '1px solid rgba(2, 23, 34, 0.1)',
            },
        },

        MUIDataTablePagination: {
            toolbar: {
                padding: 0,
                '& > p:nth-child(2)': {
                    '@media (max-width: 400px)': {
                        display: 'none',
                    },
                },
            },
            root: {
                padding: 0,
            },
        },
    },
});
