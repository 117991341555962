export const updateFeedBack = (payload) => (dispatch) => {
    dispatch({ type: 'UPDATE_SNACK', payload: payload });
};

export const startLoading = (payload) => (dispatch) => {
    dispatch({ type: 'START_LOADING', payload: payload });
};

export const rightDrawer = (payload) => (dispatch) => {
    dispatch({ type: 'RIGHT_DRAWER', payload: payload });
};
export const leftDrawer = (payload) => (dispatch) => {
    dispatch({ type: 'LEFT_DRAWER', payload: payload });
};
