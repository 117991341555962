import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
const useStyles = makeStyles((theme) => ({
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
    },
    Cardclass: {
        borderRadius: 5,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '250px 530px',
        width: '250px',
        height: '530px',
        boxShadow:
            'inset 0px 3px 2px 1px rgb(18 17 17 / 84%), 0px 1px 1px 0px rgb(18 18 18 / 79%),inset -1px -7px 0px 0px rgb(34 32 32 / 3%)',
    },
    Cardclassmain: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '260px 530px',
        width: '260px',
        height: '530px',
    },
    cardHeader: {
        color: '#FFFFFF',
        textTransform: 'capitalize',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
    },
    divider: {
        height: 2,
        background: '#f0f0f0',
        margin: 20,
    },
    btndiv: {
        padding: 16,
    },
    btnclass: {
        fontSize: 13,
        background: '#F2994A',
        color: '#FFF',
        '&:disabled': {
            boxShadow: 'none',
            backgroundColor: theme.palette.secondary.main + '!important',
            color: '#FFFFFF !important',
        },
    },
    contentbox1: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: '250px 265px',
        width: '250px',
        height: '265px',
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    flexItem: {
        margin: 5,
        textAlign: 'center',
    },
}));
const SubPlanButton = (props) => {
    const classes = useStyles();
    const {
        i,
        signupflag,
        pagechange,
        paymentflag,
        packduration,
        paymentFn,
        upgradeflag,
        btnindex,
        ind,
        subsType,
        UpgradeFn,
        redirectFn,
    } = props;

    return (
        <Box component="div" className={classes.btndiv}>
            {(signupflag || pagechange || paymentflag) && (
                <Button
                    fullWidth
                    className={classes.btnclass}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                        i.subscriptionPlan === 'free forever' &&
                        (packduration === 'monthly' || packduration === 'yearly')
                            ? paymentFn(i._id, 'free forever', 1)
                            : i.subscriptionPlan === 'individual' && packduration === 'monthly'
                              ? paymentFn(i._id, i.subType[0].type, 1)
                              : i.subscriptionPlan === 'individual' && packduration === 'yearly'
                                ? paymentFn(i._id, i.subType[1].type, 1)
                                : i.subscriptionPlan === 'professional' && packduration === 'monthly'
                                  ? paymentFn(i._id, i.subType[0].type, 1)
                                  : i.subscriptionPlan === 'professional' && packduration === 'yearly'
                                    ? paymentFn(i._id, i.subType[1].type, 1)
                                    : i.subscriptionPlan === 'team' && packduration === 'monthly'
                                      ? paymentFn(i._id, i.subType[0].type, 5)
                                      : i.subscriptionPlan === 'team' && packduration === 'yearly'
                                        ? paymentFn(i._id, i.subType[1].type, 5)
                                        : (i.subscriptionPlan === 'enterprise' && packduration === 'yearly') ||
                                            (i.subscriptionPlan === 'enterprise' && packduration === 'monthly')
                                          ? redirectFn()
                                          : null
                    }
                >
                    {(i.subscriptionPlan === 'enterprise' && packduration === 'yearly') ||
                    (i.subscriptionPlan === 'enterprise' && packduration === 'monthly')
                        ? 'Contact Sales'
                        : '   Continue'}
                </Button>
            )}
            {upgradeflag && (
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() =>
                        i.subscriptionPlan === 'free forever' &&
                        (packduration === 'monthly' || packduration === 'yearly')
                            ? UpgradeFn(i._id, 'free forever', 1)
                            : i.subscriptionPlan === 'individual' && packduration === 'monthly'
                              ? UpgradeFn(i._id, i.subType[0].type, 1)
                              : i.subscriptionPlan === 'individual' && packduration === 'yearly'
                                ? UpgradeFn(i._id, i.subType[1].type, 1)
                                : i.subscriptionPlan === 'professional' && packduration === 'monthly'
                                  ? UpgradeFn(i._id, i.subType[0].type, 1)
                                  : i.subscriptionPlan === 'professional' && packduration === 'yearly'
                                    ? UpgradeFn(i._id, i.subType[1].type, 1)
                                    : i.subscriptionPlan === 'team' && packduration === 'monthly'
                                      ? UpgradeFn(i._id, i.subType[0].type, 5)
                                      : i.subscriptionPlan === 'team' && packduration === 'yearly'
                                        ? UpgradeFn(i._id, i.subType[1].type, 5)
                                        : (i.subscriptionPlan === 'enterprise' && packduration === 'monthly') ||
                                            (i.subscriptionPlan === 'enterprise' && packduration === 'yearly')
                                          ? redirectFn()
                                          : null
                    }
                    disabled={
                        (btnindex === ind && (subsType === packduration || subsType === 'free forever')) ||
                        (i.subscriptionPlan === 'individual' && props.subsType === 'lifetime')
                    }
                    className={classes.btnclass}
                >
                    {(i.subscriptionPlan === 'individual' && props.subsType === 'lifetime' && 'Current Plan') ||
                        (btnindex === ind && subsType === packduration && 'Current Plan') ||
                        (btnindex > ind && 'Select') ||
                        (btnindex < ind && i.subscriptionPlan !== 'enterprise' && ' Upgrade') ||
                        (btnindex < ind && i.subscriptionPlan === 'enterprise' && ' Contact Sales') ||
                        (btnindex === ind && subsType !== packduration && subsType === 'free forever'
                            ? ' Current Plan'
                            : i.subscriptionPlan === 'enterprise'
                              ? ' Contact Sales'
                              : ' Upgrade')}
                </Button>
            )}
        </Box>
    );
};

export default SubPlanButton;
