const INIT_STATE = {
    loading: false,
    appointmentloading: false,
    uploading: false,
};

const StartLoadingReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'START_LOADING':
            return { ...state, ...action.payload };
        case 'APPOINTEMENT_LOADING':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default StartLoadingReducer;
