/* eslint-disable */
const INIT_STATE = {
    tempalteName: '',
    formName: '',
    title: '',
    category: '',
    Category: null,
    Category_Count: null,
    Template_By_Category: null,
    formSubmission: null,
    dup_status: 'Enabled',
    type: '',
    content: [],
    formEmail: null,
    shortUrl: '',

    config: {
        language: 'English',
        form_status: 'Enable',
        hidden: false,
        timezone: 'GTM-5:00 - Eastern Time (US & Canada)',
        password_protection: '',
        formScheduler_status: '',
        hipaa_bidge: '',
        imgsrc: '',
        imgname: '',
        buttontext: 'submit',
        buttonColor: '#fff',
        buttonBackgroundColor: '#3A71D5',
        buttonAlignment: 'left',
        formcolor: '#fff',
        pagecolor: '#f0f0f0',
        pageBackground: '',
        pageBackgroundname: '',
        fontcolor: '#000',
        mode: 'lightmode',
        printbutton: false,
        clearbutton: false,
        requiredAll: false,
        removeBranding: false,
        hiddenAll: false,
        AlignmentAll: 'left',
        fontSize: 16,
        fontSizeCheck: true,
        spacing: 20,
        spacingCheck: true,
        fontFamily: '',
        titlealignment: 'flex-start',
        subtitle: '',
        titlesize: 24,
        titleimgsrc: '',
        titleimgname: '',
        logowidth: 100,
        logoheight: 100,
        logowidthshow: true,
        logoheightshow: true,
        titlelogowidth: 100,
        titlelogoheight: 100,
        titlelogowidthshow: true,
        titlelogoheightshow: true,
        scheduler_status: '',
        formDescription: '',
        formstart: '',
        formclose: '',
        limit: '',
        limitmsg: '',
        schedulerstart: '',
        schedulerclose: '',
        schedulerlimit: '',
        schedulermsg: '',
        footerBackgroundColor: '#FFFFFF',
        labeltext: 'Create your form with',
        companylogo: '',
        companylogoname: '',
        buttonbg: '#3A71D5',
        footertextcolor: '#FFFFFF',
        footerbuttontext: "Create your form- it's free!",
        buttonlinktext: '',
        reportlinklabel: 'Report abuse',
        reportlinktext: '',
    },
    flag: false,

    previewtype: 'desktop',
    publicFlag: 'public',
    thanku_page: 'Thank you! Your response has been submitted.',
    rateform: '',
    redirect_option: {
        link: 'Thank you! Your response has been submitted.',
        type: '',
    },
    setting: {
        notification_email: [],
        confirmation_email: '',
        link: 'http://',
        conditions: [],
        autoResponseStatus: 'Disabled',
        autoResponseObj: {},
    },
    share: {
        share_link: '',
        authority: '',
        invite_email: '',
    },
    payment_setting: {
        isConnected: false,
        liveMode: true,
        currency: 'USD',
        productType: 'Sell Products',
        pubKey: null,
        refreshToken: null,
        stripeUserId: null,
        description: 'description',
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'NEW_FORM':
            return {
                ...state,
                tempalteName: '',

                Category_Count: null,
                Template_By_Category: null,
                formSubmission: null,
                type: '',
                content: [],

                config: {
                    language: 'English',
                    form_status: 'Enable',
                    form_review: 'Enable',
                    timezone: 'GTM-5:00 - Eastern Time (US & Canada)',
                    password_protection: '',
                    formScheduler_status: '',
                    dup_status: '',
                    hipaa_bidge: '',
                    imgsrc: '',
                    imgname: '',
                    buttontext: 'submit',
                    buttonColor: '#fff',
                    buttonBackgroundColor: '#3A71D5',
                    buttonAlignment: 'left',
                    formcolor: '#fff',
                    pagecolor: '#f0f0f0',
                    pageBackground: '',
                    pageBackgroundname: '',
                    fontcolor: '#000',
                    mode: 'lightmode',
                    colorScheme: 1,
                    printbutton: false,
                    clearbutton: false,
                    requiredAll: false,
                    removeBranding: false,
                    AlignmentAll: 'left',
                    fontSize: 16,
                    fontSizeCheck: true,
                    spacing: 20,
                    spacingCheck: true,
                    fontFamily: 'Nunito',
                    titlealignment: 'flex-start',
                    subtitle: '',
                    titlesize: 24,
                    titleimgsrc: '',
                    titleimgname: '',
                    headimgsrc: '',
                    imagesrc: '',
                    imagesrcname: '',
                    logowidth: 100,
                    logoheight: 100,
                    logowidthshow: true,
                    logoheightshow: true,
                    titlelogowidth: 100,
                    titlelogoheight: 100,
                    titlelogowidthshow: true,
                    titlelogoheightshow: true,
                    imagewidth: 350,
                    imageheight: 150,
                    scheduler_status: '',
                    formDescription: '',
                    formstart: '',
                    formclose: '',
                    limit: '',
                    limitmsg: '',
                    schedulerstart: '',
                    schedulerclose: '',
                    schedulerlimit: '',
                    schedulermsg: '',
                    footerBackgroundColor: '#FFFFFF',
                    labeltext: 'Powered by',
                    companylogo: '',
                    companylogoname: '',
                    buttonbg: '#3A71D5',
                    footertextcolor: '#FFFFFF',
                    footerbuttontext: "Create your form- it's free!",
                    buttonlinktext: '',
                    reportlinklabel: 'Report abuse',
                    reportlinktext: '',
                },
                flag: false,
                previewtype: 'desktop',
                publicFlag: 'public',
                thanku_page: 'Thank you! Your response has been submitted.',
                rateform: '',
                redirect_option: {
                    link: 'Thank you! Your response has been submitted.',
                    type: '',
                },
                setting: {
                    notification_email: '',
                    confirmation_email: '',
                    link: '',
                    conditions: [],
                    autoResponseStatus: 'Disabled',
                    autoResponseObj: {},
                },
                share: {
                    share_link: '',
                    authority: '',
                    invite_email: '',
                },
                payment_setting: {
                    isConnected: false,
                    liveMode: true,
                    currency: 'USD',
                    productType: 'Sell Products',
                    pubKey: null,
                    refreshToken: null,
                    stripeUserId: null,
                    description: 'description',
                },
            };

        case 'UPDATE_CREATE_DATA':
            return { ...state, ...action.payload };
        case 'UPDATE_CREATE_DATA_TEMPLATE':
            return { ...state, ...action.payload };
        case 'TEMPLATE_CATEGORY':
            return { ...state, ...action.payload };
        case 'FORM_SUBMISSION':
            return { ...state, ...action.payload };
        case 'CATEGORY_COUNT':
            return { ...state, ...action.payload };
        case 'TEMPLATE_BY_CATEGORY':
            return { ...state, ...action.payload };
        case 'UPDATE_SETTINGS':
            return { ...state, config: { ...state.config, ...action.payload } };
        case 'UPDATE_NOTIFICATION_EMAILS':
            return { ...state, setting: { ...state.setting, ...action.payload } };
        case 'UPDATE_SETTING':
            return { ...state, setting: { ...state.setting, ...action.payload } };
        case 'UPDATE_CONDITIONAL_LOGIC':
            return {
                ...state,
                setting: {
                    ...state.setting,
                    conditions: action.payload,
                },
            };
        case 'UPDATE_REDIRECT_OPTIONS':
            return {
                ...state,
                setting: { ...state.setting, ...action.payload },
            };
        case 'UPDATE_SHARE':
            return { ...state, share: { ...state.share, ...action.payload } };
        case 'PAYMENT_SETTING':
            return {
                ...state,
                payment_setting: { ...state.payment_setting, ...action.payload },
            };
        case 'UPDATE_FOOTER':
            return { ...state, config: { ...state.config, ...action.payload } };
        default:
            return state;
    }
};
