import {
    Box,
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Typography,
} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { React, useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { registerWorkspaceMemeber, verfyWorkspaceInvite } from 'redux/action/workspaceActivity.action';
import store from 'redux/store';
import isEmail from 'validator/es/lib/isEmail';
import * as constants from 'Utils/domainSuffix';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        width: '100%',

        textAlign: 'center',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        textTransform: 'capitalize',

        fontSize: '14px',
        textAlign: 'center',
        color: '#FFFFFF',
        background: '#3A70D4',
        '&:hover': {
            background: '#3A70D4',
            color: '#FFF',
            transform: 'translateY(-5%)',
        },
    },
}));

export default function Workspace() {
    const classes = useStyles();
    const history = useHistory();
    const cookies = new Cookies();
    const [showpassword, setShowPassword] = useState(false);
    const [firstname, setfirstname] = useState('');
    const [firstnameerrtext, setfirstnameerrtext] = useState('');
    const [firstnameerr, setfirstnameerr] = useState(false);
    const [lastname, setlastname] = useState('');
    const [lastnameerr, setlastnameerr] = useState(false);
    const [lastnameerrtext, setlastnameerrtext] = useState('');
    const [spassword, setspassword] = useState('');
    const [spassworderr, setspassworderr] = useState(false);
    const [spassworderrtext, setspassworderrtext] = useState('');

    const [loadingBtn, setloadingBtn] = useState(false);
    const [initloading, setInitloading] = useState(false);

    const searchParms = new URLSearchParams(history.location.search);

    const workspaceId = searchParms.get('workspaceId');
    const email = searchParms.get('email');
    const firstName = searchParms.get('firstName');
    const lastName = searchParms.get('lastName');
    const code = searchParms.get('code');
    const [showMessage, setShowMessage] = useState(null);

    const handleClickShowPassword = () => {
        setShowPassword(!showpassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (!workspaceId || !email || !firstName || !lastName || !code) {
            store.dispatch({
                type: 'UPDATE_SNACK',
                payload: {
                    snackbar: true,
                    message: 'Invalid Url',
                    type: 'error',
                },
            });
            return;
        }

        const credentials = {
            workspaceId,
            email,
            firstName,
            lastName,
            code,
        };
        setInitloading(true);
        verfyWorkspaceInvite(credentials)
            .then((res) => {
                if (res) {
                    redirectChannel();
                }
                setInitloading(false);
            })
            .catch((err) => {
                if (err.code === 406) {
                    setfirstname(firstName);
                    setlastname(lastName);
                } else if (err.code === 410) {
                    setShowMessage(0);
                } else if (err.code === 409) {
                    redirectChannel();
                    return;
                } else {
                    setShowMessage(0);
                }
                setInitloading(false);
            });
    }, []);

    const redirectChannel = () => {
        localStorage.clear();
        cookies.remove('token', { domain: constants.CURRENT_DOMAIN_SUFFIX });
        window.location.replace(process.env.REACT_APP_INTELY_PLATFORM_EXTERNAL_HOST);
    };

    const signupFn = () => {
        setfirstnameerr(false);
        setlastnameerr(false);

        setspassworderr(false);
        let hasErr = false;
        let name = /^[a-zA-Z ]+$/;
        let passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$!#%*?&]{7,14}$/;

        if (!firstname) {
            setfirstnameerr(true);
            setfirstnameerrtext('Please enter Firstname');
            hasErr = true;
        } else if (!name.test(firstname)) {
            setfirstnameerr(true);
            setfirstnameerrtext('Firstname should be alphabets');
            hasErr = true;
        }

        if (!lastname) {
            setlastnameerr(true);
            setlastnameerrtext('Please enter Lastname');
            hasErr = true;
        } else if (!name.test(lastname)) {
            setlastnameerr(true);
            setlastnameerrtext('Lastname should be alphabets');
            hasErr = true;
        }

        if (!email.toLowerCase() || !isEmail(email.toLowerCase())) {
            hasErr = true;
        }

        if (!spassword || spassword.length < 7) {
            setspassworderr(true);
            setspassworderrtext('Password should have at least 7 characters');
            hasErr = true;
        } else if (!passwordRegex.test(spassword)) {
            setspassworderr(true);
            setspassworderrtext('Please enter valid New Password like: John@123');
            hasErr = true;
        }

        if (hasErr) {
            return true;
        }

        if (firstname && email.toLowerCase() && spassword) {
            setloadingBtn(true);
            const credentials = {
                workspaceId,
                email,
                firstName: firstname,
                lastName: lastname,
                password: spassword,
                code,
            };

            registerWorkspaceMemeber(credentials)
                .then((res) => {
                    setloadingBtn(false);
                    if (res) {
                        redirectChannel();
                    }
                })
                .catch((err) => {
                    setloadingBtn(false);
                });
        }
    };

    const GetMessageComponent = () => {
        if (showMessage === 0) {
            return (
                <div>
                    <Typography variant="h3">INVITATION LINK EXPIRED</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        Hi, It looks like you're too late to activate your account or it has been cancelled by admin.
                    </Typography>
                    <Typography>But don't worry, you can contact the administrator to ask for the new link.</Typography>
                </div>
            );
        }

        return '';
    };

    if (initloading) {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    zIndex: 10,
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (showMessage !== null) {
        return (
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}
            >
                <GetMessageComponent />
            </Box>
        );
    }

    return (
        <div className={classes.root}>
            <Grid container justifyContent="center" style={{ marginTop: 60 }}>
                <Grid item xs={6}>
                    <Typography component="h1" variant="h5">
                        You have been invited to join a Workspace!
                    </Typography>
                    <Typography component="h1" variant="h5">
                        Please create your an account.
                    </Typography>
                    <div>
                        <FormControl
                            fullWidth
                            margin="normal"
                            disabled
                            InputProps={{
                                classes: { notchedOutline: classes.noBorder },
                            }}
                        >
                            <OutlinedInput
                                type="text"
                                variant="outlined"
                                id="email"
                                name="email"
                                value={email}
                                placeholder="Email"
                                size="small"
                            />
                        </FormControl>
                    </div>
                    <div>
                        <FormControl
                            fullWidth
                            margin="normal"
                            error={firstnameerr}
                            className={classes.textField}
                            InputProps={{
                                classes: { notchedOutline: classes.noBorder },
                            }}
                        >
                            <OutlinedInput
                                type="text"
                                variant="outlined"
                                id="firstname"
                                name="firstname"
                                defaultValue={firstName}
                                autoComplete="firstname"
                                placeholder="First Name"
                                onChange={(e) => setfirstname(e.target.value)}
                            />
                            <FormHelperText error={firstnameerr}>
                                {firstnameerr ? firstnameerrtext : null}
                            </FormHelperText>
                        </FormControl>
                        <FormControl
                            type="text"
                            fullWidth
                            margin="normal"
                            error={lastnameerr}
                            className={classes.textField}
                            InputProps={{
                                classes: { notchedOutline: classes.noBorder },
                            }}
                        >
                            <OutlinedInput
                                type="text"
                                variant="outlined"
                                id="lastname"
                                name="lastname"
                                defaultValue={lastName}
                                autoComplete="lastname"
                                placeholder="Last Name"
                                onChange={(e) => setlastname(e.target.value)}
                            />
                            <FormHelperText error={lastnameerr}>{lastnameerr ? lastnameerrtext : null}</FormHelperText>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl
                            fullWidth
                            margin="normal"
                            error={spassworderr}
                            InputProps={{
                                classes: { notchedOutline: classes.noBorder },
                            }}
                        >
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showpassword ? 'text' : 'password'}
                                placeholder="New password"
                                value={spassword}
                                onChange={(e) => setspassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showpassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText error={spassworderr}>
                                {spassworderr ? spassworderrtext : null}
                            </FormHelperText>
                        </FormControl>
                    </div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        onClick={signupFn}
                        disabled={loadingBtn}
                    >
                        {loadingBtn ? 'Loading ...' : 'Sign up'}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}
