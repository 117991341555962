import { combineReducers } from 'redux';
import feedback from './snackbar.reducer';
import loading from './startloading.reducer';
import CreateForm from './createForm.reducer';
import rightDraw from './rightDraw.reducer';
import leftDraw from './leftDraw.reducer';
import SubmitForm from './Submitform.reducer';
import Auth from './auth.reducer';
import globalSearch from './globalSearch.reducer';
import userProfile from './editProfile.reducer';
import workspace from './workspace.reducer';
import currentOrganization from './currentOrganization.reducer';
import stripeCheckout from './StripeCheckout.reducer';
import SchedulingModule from './SchedulingModule.reducer';

const reducers = combineReducers({
    feedback: feedback,
    loading: loading,
    createForm: CreateForm,
    rightDraw: rightDraw,
    leftDraw: leftDraw,
    submitForm: SubmitForm,
    auth: Auth,
    globalSearch: globalSearch,
    userProfile: userProfile,
    workspace: workspace,
    currentOrganization: currentOrganization,
    stripeCheckout: stripeCheckout,
    SchedulingModule: SchedulingModule,
});

export default reducers;
