import { createStore, applyMiddleware, compose } from 'redux';

import thunkMiddleware from 'redux-thunk';

import reducers from './reducer';

const store = createStore(
    reducers,
    compose(
        applyMiddleware(thunkMiddleware),

        //For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
            : function (f) {
                  return f;
              },
    ),
);

export default store;
