import Clear from '@material-ui/icons/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import * as React from 'react';
import { useSelector } from 'react-redux';
import store from 'redux/store';

export default function SearchBar() {
    const searchingText = useSelector((state) => state.globalSearch.searchingText);

    const handleSearch = (e) => {
        store.dispatch({
            type: 'SEARCHING',
            payload: {
                searchingText: e.target.value,
            },
        });
    };

    const handleClear = () => {
        store.dispatch({
            type: 'SEARCHING',
            payload: {
                searchingText: '',
            },
        });
    };

    return (
        <Paper
            sx={{
                p: '1px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 300,
                mr: 2,
            }}
            variant="outlined"
        >
            <IconButton size="small" aria-label="menu">
                <SearchIcon fontSize="small" />
            </IconButton>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search..."
                value={searchingText}
                inputProps={{ 'aria-label': 'search google maps' }}
                onChange={handleSearch}
            />
            <IconButton size="small" aria-label="directions" onClick={handleClear}>
                <Clear fontSize="small" />
            </IconButton>
        </Paper>
    );
}
