const INIT_STATE = {
    loggedIn: false,
};

const AuthReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_AUTH':
            return { ...state, loggedIn: action.payload };
        default:
            return state;
    }
};

export default AuthReducer;
