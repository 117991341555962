const INIT_STATE = {
    getPersonnelById: null,
    getAllPersonnel: [],
    getLocationById: null,
    getAllLocation: [],
    getAppointmentBlockById: null,
    getAllAppointmentBlock: [],
    getCalendarById: null,
    getAllCalendar: [],
};

const SchedulingModule = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SCHEDULING_MODULE':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default SchedulingModule;
