const INIT_STATE = {
    open: false, //for draw
    width: 0,
    logostate: false,
    buttonstate: false,
    formdesigner: false,
    titlechange: false,
    delete: false,
    footer: false,
};

const RightDrawer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'RIGHT_DRAWER':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default RightDrawer;
