import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const add = (props) => {
    return (
        <SvgIcon width="18" height="18" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M35.5308 10.5L33.5291 8.5C32.9036 7.875 31.9027 7.875 31.2771 8.5L29.5882 10.1875L33.8419 14.4375L35.5308 12.75C36.1564 12.125 36.1564 11.125 35.5308 10.5ZM20.5804 19.125C20.0799 19.625 19.7046 20.1875 19.5169 20.875L18.5786 24H17.0148C16.7646 24 16.5143 23.875 16.3892 23.625C15.6386 22.125 13.5117 21.75 12.2606 22.75L11.3849 20.125C11.1972 19.5 10.6342 19.125 9.94613 19.125C9.32059 19.125 8.7576 19.5 8.56994 20.125L7.44396 23.5625C7.31885 23.8125 7.06864 24 6.75586 24H6.00521C5.44222 24 5.00434 24.5 5.00434 25C5.00434 25.5625 5.44222 26 6.00521 26H6.75586C7.9444 26 8.94526 25.3125 9.32059 24.1875L10.0087 22.1875L11.0096 25.375C11.1347 25.75 11.51 26 11.8853 26C11.9479 26 11.9479 26 11.9479 26C12.3858 26 12.6985 25.8125 12.8862 25.5L13.3241 24.5C13.5117 24.1875 13.8245 24.125 13.9496 24.125C14.1373 24.125 14.45 24.1875 14.6377 24.5625C15.0756 25.4375 16.0139 26 17.0148 26H21.0182V29H2.94005V3.0625H12.9487V9.5625C12.9487 10.375 13.6368 11.0625 14.45 11.0625H21.0182V15.9375L24.0209 12.9375V8.25C24.0209 7.5 23.6455 6.6875 23.0825 6.125L17.828 0.9375C17.265 0.375 16.5143 0 15.7637 0H2.94005C1.31364 0.0625 0 1.375 0 3.0625V29.0625C0 30.6875 1.31364 32 2.94005 32H20.9557C22.6447 32 24.0209 30.6875 24.0209 29.0625V24.3125L32.4031 15.875L28.1494 11.625L20.5804 19.125ZM15.9513 3.25L20.768 8.0625H15.9513V3.25Z"
                fill="#7D8995"
            />
        </SvgIcon>
    );
};
export default add;
