import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

import axios from 'Utils/axios';
import { getUserLogin, setUserToken, logout } from 'Utils/loginUtils';
import { getFormsUserToken } from 'redux/action/loginpage.action';

const useStyles = makeStyles((theme) => ({
    typodiv: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const ProtectedRoute = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);

    window.onload = async () => {
        try {
            const userData = await getUserLogin();
            if (userData) {
                try {
                    const expressToken = await getFormsUserToken();
                    setUserToken(expressToken);
                    axios.defaults.headers.common['Authorization'] = expressToken;
                } catch (e) {
                    window.location.replace('/subscription-plans');
                }
            } else {
                logout();
            }
        } catch (e) {
            logout();
        }

        setLoading(false);
    };

    return (
        <>
            {loading === true ? (
                <div className={classes.typodiv}>
                    <div>
                        <CircularProgress />
                    </div>
                </div>
            ) : (
                <Route {...props} />
            )}
        </>
    );
};

export default ProtectedRoute;
