import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const add = (props) => {
    return (
        <SvgIcon width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.24325 17.991C8.4192 18.0262 8.55995 17.9558 8.59515 17.8151L13.5569 0.747957C13.5921 0.607197 13.5217 0.431247 13.381 0.396057L12.0086 0.0089676C11.8678 -0.0262224 11.6919 0.0441575 11.6567 0.184917L6.69489 17.252C6.6597 17.3928 6.73008 17.5688 6.90603 17.6039L8.24325 17.991ZM5.32248 13.733L6.27261 12.7125C6.37818 12.607 6.37818 12.431 6.27261 12.3254L2.54247 8.9824L6.27261 5.67455C6.37818 5.56898 6.37818 5.39303 6.27261 5.28746L5.32248 4.26695C5.21691 4.16138 5.04096 4.12619 4.93539 4.23176L0.0791774 8.80645C-0.0263925 8.91202 -0.0263925 9.08797 0.0791774 9.19354L4.93539 13.733C5.04096 13.8386 5.21691 13.8386 5.32248 13.733ZM15.3164 13.733L20.1726 9.19354C20.2782 9.08797 20.2782 8.91202 20.1726 8.80645L15.3164 4.23176C15.2109 4.12619 15.0349 4.16138 14.9293 4.26695L13.9792 5.28746C13.8736 5.39303 13.8736 5.56898 13.9792 5.67455L17.7093 8.9824L13.9792 12.3254C13.8736 12.431 13.8736 12.607 13.9792 12.7125L14.9293 13.733C15.0349 13.8386 15.2109 13.8386 15.3164 13.733Z"
                fill="#7D8995"
            />
        </SvgIcon>
    );
};
export default add;
