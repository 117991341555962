import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const add = (props) => {
    return (
        <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.0714 0H1.92857C0.84375 0 0 0.883929 0 1.92857V16.0714C0 17.1562 0.84375 18 1.92857 18H16.0714C17.1161 18 18 17.1562 18 16.0714V1.92857C18 0.883929 17.1161 0 16.0714 0ZM16.0714 16.0714H1.92857V1.92857H16.0714V16.0714ZM4.82143 14.1429H5.46429C5.78571 14.1429 6.10714 13.8616 6.10714 13.5V8.35714C6.10714 8.03571 5.78571 7.71429 5.46429 7.71429H4.82143C4.45982 7.71429 4.17857 8.03571 4.17857 8.35714V13.5C4.17857 13.8616 4.45982 14.1429 4.82143 14.1429ZM8.67857 14.1429H9.32143C9.64286 14.1429 9.96429 13.8616 9.96429 13.5V4.5C9.96429 4.17857 9.64286 3.85714 9.32143 3.85714H8.67857C8.31696 3.85714 8.03571 4.17857 8.03571 4.5V13.5C8.03571 13.8616 8.31696 14.1429 8.67857 14.1429Z"
                fill="#7D8995"
            />
        </SvgIcon>
    );
};
export default add;
