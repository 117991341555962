const INIT_STATE = {
    list: [],
    myworkspace: null,
    data: [],
    latestFormInfoStatus: false,
    allOrganizations: [],
    allorgname: [],
    getOrgData: [],
};

const WorkspaceReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'WORKSPACE_LIST':
            return { ...state, ...action.payload };
        case 'GET_WORKSPACE_DATA':
            return { ...state, ...action.payload };
        case 'SET_WORKSPACE':
            return { ...state, ...action.payload };
        case 'SET_LATEST_FORM_INFO_STATUS':
            return { ...state, ...action.payload };
        case 'GET_ALL_ORG_NAME':
            return { ...state, ...action.payload };
        case 'GET_ORG_DATA':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default WorkspaceReducer;
