import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Cookies } from 'react-cookie';
import { useIdleTimer } from 'react-idle-timer';
import * as constants from 'Utils/domainSuffix';
import { logout } from './loginUtils';

const cookies = new Cookies();
const useStyles = makeStyles((theme) => ({
    Dialogstyle: {
        '& .MuiDialogTitle-root': {
            textAlign: 'center',
        },
        '& .MuiDialogActions-root': {
            justifyContent: 'center',
        },
        '& .MuiButtonBase-root:first-child': {
            color: theme.palette.primary.dark,
            border: '1px solid ' + theme.palette.primary.dark,

            '&:hover': {
                color: '#fff',
                background: theme.palette.primary.dark,
            },
        },
        '& .MuiButtonBase-root:last-child': {
            border: '1px solid ' + theme.palette.primary.main,
            color: '#fff',
            '&:hover': {
                color: '#fff',
                background: theme.palette.primary.main,
            },
        },
    },
}));
const SessionModal = () => {
    const classes = useStyles();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { isIdle } = useIdleTimer({
        timeout: 1000 * 60 * 10,
        crossTab: {
            emitOnAllTabs: true,
        },
        onIdle: () => {
            setShowConfirmationModal(true);
            setTimeout(() => isIdle() && handleCancel(), 1000 * 60);
        },
        startOnMount: true,
        debounce: 1000,
    });

    const handleOk = () => {
        setShowConfirmationModal(false);
    };

    const handleCancel = () => {
        cookies.remove('token', { domain: constants.CURRENT_DOMAIN_SUFFIX });
        setShowConfirmationModal(false);
        logout();
    };

    return (
        <Dialog
            open={showConfirmationModal}
            onClose={() => setShowConfirmationModal(false)}
            maxWidth="xs"
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ root: classes.Dialogstyle }}
        >
            <DialogTitle id="alert-dialog-title">{'Your session timed out , do you want to continue?'}</DialogTitle>
            <DialogActions>
                <Button variant="contained" size="small" onClick={handleCancel}>
                    No ,log me out
                </Button>
                <Button variant="contained" size="small" onClick={handleOk} color="primary" autoFocus>
                    Yes continue
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SessionModal;
