import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { React, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { LoginWithPaymentFn } from 'redux/action/Payment.action';
const useStyles = makeStyles((theme) => ({
    typodiv: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
const PaymentSuccess = (props) => {
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    const search = useLocation().search;
    const sessionId = new URLSearchParams(search).get('session_id');

    useEffect(() => {
        setLoading(true);
        LoginWithPaymentFn({ sessionId: sessionId })
            .then((res) => {
                localStorage.removeItem('email');
                localStorage.setItem('token', res.userId + ' ' + res.token);
                window.location.replace('/');
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    }, [sessionId]);

    return (
        <>
            {loading === true && (
                <div className={classes.typodiv}>
                    <div>
                        <CircularProgress />
                    </div>
                </div>
            )}
        </>
    );
};

export default PaymentSuccess;
