import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const add = (props) => {
    return (
        <SvgIcon width="28" height="28" viewBox="0 0 28 28" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 16.3447V11.6553L3.70973 11.037C3.93126 10.266 4.23915 9.52384 4.62933 8.81995L2.44253 5.75845L5.75845 2.44253L8.81994 4.62933C9.52373 4.2392 10.2661 3.93131 11.037 3.70973L11.6553 0H16.3447L16.963 3.70973C17.7339 3.93131 18.4763 4.23925 19.1801 4.62933L22.2416 2.44253L25.5575 5.75845L23.3707 8.81995C23.7608 9.52379 24.0687 10.2661 24.2903 11.037L28 11.6553V16.3447L24.2903 16.963C24.0687 17.7339 23.7608 18.4763 23.3707 19.1801L25.5575 22.2416L22.2416 25.5575L19.1801 23.3707C18.4762 23.7609 17.734 24.0687 16.963 24.2903L16.3447 28H11.6553L11.037 24.2903C10.2661 24.0687 9.52373 23.7608 8.81994 23.3707L5.75845 25.5575L2.44253 22.2416L4.62933 19.1801C4.2392 18.4763 3.93126 17.7339 3.70973 16.963L0 16.3447ZM14 21C17.8598 21 21 17.8598 21 14C21 10.1402 17.8598 7 14 7C10.1402 7 7 10.1402 7 14C7 17.8598 10.1402 21 14 21Z" />
        </SvgIcon>
    );
};
export default add;
