import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { Provider } from 'react-redux';
import SnackBar from 'Utils/SnackBar';
import Loading from 'Utils/Loading';
import ConfirmationModal from 'Utils/ConfirmationModal';
import SessionModal from 'Utils/IdleTimeout';
import { ThemeProvider } from '@material-ui/core/styles';
import { lightTheme } from './theme/theme';
import Routes from 'route/index';
import FormsErrorBoundary from 'components/FormsErrorBoundary';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={lightTheme}>
                <FormsErrorBoundary>
                    <SnackBar />
                    <Loading />
                    {localStorage.getItem('token') && !window.location.href.includes('myform') && <SessionModal />}
                    <ConfirmationModal>
                        <Suspense fallback={<Loading loading />}>
                            <Routes />
                        </Suspense>
                    </ConfirmationModal>
                </FormsErrorBoundary>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
