import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const add = (props) => {
    return (
        <SvgIcon width="13" height="14" viewBox="0 0 13 14" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.125 7.875C8.28516 7.875 10.0625 6.125 10.0625 3.9375C10.0625 1.77734 8.28516 0 6.125 0C3.9375 0 2.1875 1.77734 2.1875 3.9375C2.1875 6.125 3.9375 7.875 6.125 7.875ZM6.125 1.3125C7.54688 1.3125 8.75 2.51562 8.75 3.9375C8.75 5.38672 7.54688 6.5625 6.125 6.5625C4.67578 6.5625 3.5 5.38672 3.5 3.9375C3.5 2.51562 4.67578 1.3125 6.125 1.3125ZM8.61328 8.33984L7.08203 12.5508L6.5625 10.2812L7.4375 8.75H4.8125L5.6875 10.2812L5.14062 12.5508L3.60938 8.33984C1.58594 8.36719 0 9.98047 0 12.0039V12.6875C0 13.4258 0.574219 14 1.3125 14H10.9375C11.6484 14 12.25 13.4258 12.25 12.6875V12.0039C12.25 9.98047 10.6367 8.36719 8.61328 8.33984ZM1.3125 12.6875V12.0039C1.3125 11.0195 1.88672 10.1992 2.76172 9.81641L3.80078 12.6875H1.3125ZM10.9375 12.6875H8.42188L9.48828 9.81641C10.3359 10.1992 10.9375 11.0195 10.9375 12.0039V12.6875Z" />
        </SvgIcon>
    );
};

export default add;
