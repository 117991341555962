const INIT_STATE = {
    getUser: {},
    emailConfig: [],
};

const EditProfile = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'STORE_USER':
            return { ...state, ...action.payload };
        case 'STORE_EMAIL_CONFIG':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default EditProfile;
