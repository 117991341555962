import axios from 'Utils/axios';
import store from 'redux/store';

export const setWorkspaceDefaultId = (workspacelist) => {
    let myworkspace = null;

    if (!(workspacelist?.length > 0)) {
        return null;
    }

    if (localStorage.getItem('workspaceId')) {
        myworkspace = workspacelist.find((item) => item._id == localStorage.getItem('workspaceId'));
    } else {
        myworkspace = workspacelist.find((item) => item.workspaceType === 'personal');
    }

    if (!myworkspace) {
        myworkspace = workspacelist[0];
    }

    if (!myworkspace) {
        return null;
    }

    axios.defaults.headers.common['workspaceId'] = myworkspace._id;
    localStorage.setItem('workspaceId', myworkspace._id);

    store.dispatch({
        type: 'WORKSPACE_LIST',
        payload: {
            list: workspacelist,
            myworkspace: myworkspace,
        },
    });
};

export const setWorkspaceId = (val) => {
    axios.defaults.headers.common['workspaceId'] = val._id;
    localStorage.setItem('workspaceId', val._id);
    store.dispatch({
        type: 'SET_WORKSPACE',
        payload: {
            myworkspace: val,
        },
    });
};
