/* eslint default-param-last: 0 */
const INIT_STATE = {};

const currentOrg = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'CURRENT_ORGANIZATION':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default currentOrg;
