export const ENVIRONMENT_LOCAL = 'local';
export const ENVIRONMENT_TEST = 'test';
export const ENVIRONMENT_DEVELOPMENT = 'dev';
export const ENVIRONMENT_STAGING = 'staging';
export const ENVIRONMENT_PRODUCTION = 'production';

export const DOMAIN_INTELY = 'intely.io';

let domainSuffix;
switch (process.env.REACT_APP_CURRENT_ENVIRONMENT) {
    case ENVIRONMENT_PRODUCTION:
        domainSuffix = DOMAIN_INTELY;
        break;
    case ENVIRONMENT_STAGING:
        domainSuffix = 'stg.' + DOMAIN_INTELY;
        break;
    case ENVIRONMENT_DEVELOPMENT:
        domainSuffix = 'dev.' + DOMAIN_INTELY;
        break;
    case ENVIRONMENT_TEST:
        domainSuffix = 'test.' + DOMAIN_INTELY;
        break;
    case ENVIRONMENT_LOCAL:
    default:
        domainSuffix = 'local.' + DOMAIN_INTELY;
        break;
}
export const CURRENT_DOMAIN_SUFFIX = domainSuffix;
