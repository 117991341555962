import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const add = (props) => {
    return (
        <SvgIcon width="14" height="14" {...props} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 6H6V0H0V6ZM1.25 1.25H4.75V4.75H1.25V1.25ZM8 0V6H14V0H8ZM12.75 4.75H9.25V1.25H12.75V4.75ZM0 14H6V8H0V14ZM1.25 9.25H4.75V12.75H1.25V9.25ZM2.25 10.25V11.75H3.75V10.25H2.25ZM2.25 2.25V3.75H3.75V2.25H2.25ZM11.75 3.75V2.25H10.25V3.75H11.75ZM13 8V9H11V8H8V14H9V11H10V12H14V8H13ZM13 13V14H14V13H13ZM11 13V14H12V13H11Z" />
        </SvgIcon>
    );
};
export default add;
