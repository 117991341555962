import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { startLoading } from 'redux/action/utils.action';
const styles = (theme) => ({
    loadingdiv: {
        display: 'flex',
        top: 0,
        position: 'absolute',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255,255,255,1)',
        zIndex: 1000,
        height: '100vh',
    },
});

class Loading extends React.Component {
    render() {
        return (
            <>
                {this.props.loading ? (
                    <div
                        className={this.props.classes.loadingdiv}
                        style={{
                            background: this.props.uploading ? 'rgba(255,255,255,0.4)' : 'rgba(255,255,255,1)',
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : null}
            </>
        );
    }
}

Loading.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        ...state.loading,
    };
};

export default connect(mapStateToProps, { startLoading })(withStyles(styles)(Loading));
