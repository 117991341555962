import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    CircularProgress,
    Collapse,
    ListItem,
    ListItemText,
    Tooltip,
    Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Menu from '@material-ui/core/Menu';
import { MenuItem } from '@mui/material';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CloudDownload } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import HomeIcon from '@material-ui/icons/Home';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ArticleIcon from '@mui/icons-material/Article';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import Fade from '@mui/material/Fade';
import AdminSettings from 'assets/icons/AdminSettings';
import AllApps from 'assets/icons/AllApps';
import FormSettings from 'assets/icons/FormSettings';
import Dropdownlogout from 'assets/icons/Logout1';
import Myuser from 'assets/icons/Myuser';
import IntelyForm from 'assets/images/intelyForms_Logos.png';
import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { getAccountInfo } from 'redux/action/editProfile.action';
import { getUserIntegrationActivity } from 'redux/action/integrationHistory.action';
import { getUserLoginActivity } from 'redux/action/loginActivity.action';
import store from 'redux/store';
import { avatarName } from 'Utils/AvatarName';
import { ConfirmationModal } from 'Utils/ConfirmationModal';
import SearchBar from './searchbar';
import * as constants from 'Utils/domainSuffix';
import {
    getCurrentUserData,
    getUserOrganizationData,
    setOrganizationData,
    getCurrentOrganizationId,
    parseURLQueries,
    logout,
} from '../Utils/loginUtils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);

const cookies = new Cookies();

const StyledOrganizationMenu = styled((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 170,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            textAlign: 'right',
            justifyContent: 'center',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                fontWeight: 400,
                color: '#333333',
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));
const useStyles = makeStyles((theme) => ({
    divclass: {
        background: '#FFFFFF',
        boxShadow: '0px 1px 6px rgba(123, 133, 160, 0.25)',
        height: 60,
        paddingTop: 12,
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 12,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 1000,
        '& .MuiToolbar-root': {
            borderRadius: 0,
        },
    },
    stylemenu: {
        zIndex: 2000,
        '& .MuiList-padding': {
            padding: 0,
        },
        '& .MuiMenu-paper': {
            marginTop: '15px',
            boxShadow: '0px 1px 6px rgba(123, 133, 160, 0.25)',
            maxWidth: '172px',
            minWidth: '172px',
        },
        '& .MuiPaper-rounded': {
            borderRadius: 0,
        },
    },
    stylemenu1: {
        zIndex: 2000,
        '& .MuiList-padding': {
            padding: 0,
        },
        '& .MuiMenu-paper': {
            marginTop: '10px',
            boxShadow: '0px 1px 6px rgba(123, 133, 160, 0.25)',
            maxWidth: '172px',
            minWidth: '172px',
        },
        '& .MuiPaper-rounded': {
            borderRadius: 0,
        },
    },
    divider: {
        height: 36,
        width: 1,
        background: '#D7DCEA',
        marginLeft: 10,
        marginRight: 10,
    },
    usericon: {
        marginLeft: 10,
        marginRight: 5,
        width: 36,
        height: 36,
        borderRadius: '50%',
        color: '#C4C4C4',
    },
    arrowicon: {
        height: 24,
        width: 24,
        color: '#C4C4C4',
    },
    arrowicon1: {
        height: 24,
        width: 24,
        color: theme.palette.primary.main,
    },
    mytoolbar: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        '&.MuiToolbar-regular': {
            minHeight: '30px',
        },
    },
    menulistitem: {
        border: '1px solid #fff',
        cursor: 'pointer',
        color: '#3A70D4',
        background: '#FFF',
        outline: 'none',
        '&:hover': {
            '& .MuiTypography-root': {
                color: '#fff',
            },
            background: theme.palette.primary.main,
            color: '#FFF',
        },
    },

    menulistitem1: {
        border: '1px solid #fff',
        cursor: 'pointer',
        color: '#3A70D4',
        background: '#FFF',
        outline: 'none',

        '&:hover': {
            '& .MuiTypography-root': {
                color: '#fff',
            },
            background: theme.palette.primary.main,
            color: '#FFF',
        },
    },
    menulabletext: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        paddingLeft: 10,
        color: '#000',
    },
    menulabletext1: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '12px',
        paddingLeft: 10,
        color: '#000',
    },
    menuIcon: {
        height: 16,
        width: 16,
    },
    menuIcon1: {
        height: 20,
        width: 20,
    },
    menudiv: {
        borderLeft: '1px solid #D7DCEA',
        paddingLeft: 10,
    },
    btnclass: {
        marginRight: 10,
        animation: 'pulse',
        animationDuration: '1s',
        animationIterationCount: '5',
        direction: 'alternate',
        transition: 'ease-in',
        transitionDuration: '1s',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        textTransform: 'capitalize',
    },
    btnclass1: {
        marginRight: 30,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        textTransform: 'capitalize',
    },
}));
const StyledMenu = withStyles({})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));
const IFToolbar = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    let history = useHistory();
    const modalContext = ConfirmationModal();
    const [anchorEl, setAnchorEl] = useState(null);
    const _filefy = require('filefy');

    const { profileimg, setProfileImg, setsubId } = props;
    const [organizationList, setOrganizationList] = useState([]),
        [defaultOrganization, setDefaultOrganization] = useState(null);

    const [anchorElOrganization, setAnchorElOrganization] = useState(null);
    const openOrganization = Boolean(anchorElOrganization);
    const handleOrganizationClick = (event) => {
        setAnchorElOrganization(event.currentTarget);
    };
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('md'));
    const handleUpgrade = () => {
        window.location.assign('/setting/manage-subscription');
    };
    const SettingMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOrganizationChange = (organization) => {
        setDefaultOrganization(organization);
        setOrganizationData({
            organizationId: organization.organizationId,
            organizationStripeCustomerId: organization.organizationStripeCustomerId,
        });
    };

    const getUserCurrentOrganizations = async () => {
        const user = await getCurrentUserData();

        if (user && user.userId) {
            getUserOrganizationData(user.userId)
                .then((data) => {
                    setOrganizationList(data);

                    let currentOrganizationId;
                    if (window.location.search.length) {
                        currentOrganizationId = parseURLQueries(
                            window.location.search.slice(1),
                            'currentOrganizationId',
                        )?.currentOrganizationId;
                    } else if (getCurrentOrganizationId()) {
                        currentOrganizationId = getCurrentOrganizationId();
                    }

                    if (currentOrganizationId) {
                        const organization = data.find((org) => org.organizationId === currentOrganizationId);
                        setOrganizationData({
                            organizationId: currentOrganizationId,
                            organizationStripeCustomerId: organization.organizationStripeCustomerId,
                        });
                        setDefaultOrganization(organization);
                    } else {
                        const { organizationId, organizationStripeCustomerId } = data[0];
                        setOrganizationData({
                            organizationId,
                            organizationStripeCustomerId,
                        });
                        setDefaultOrganization(data[0]);
                    }

                    store.dispatch({
                        type: 'START_LOADING',
                        payload: {
                            loading: false,
                        },
                    });
                })
                .catch((err) => {
                    store.dispatch({
                        type: 'START_LOADING',
                        payload: {
                            loading: false,
                        },
                    });
                });
        }
    };

    const SettingMenuClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        store.dispatch({
            type: 'START_LOADING',
            payload: {
                loading: true,
            },
        });

        getAccountInfo()
            .then((res) => {
                store.dispatch({
                    type: 'START_LOADING',
                    payload: {
                        loading: false,
                    },
                });

                getUserCurrentOrganizations();
                setProfileImg(res[0].profilePic);
                setsubId(res[0].subsId);
            })
            .catch((err) => {
                store.dispatch({
                    type: 'START_LOADING',
                    payload: {
                        loading: false,
                    },
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOrganizationClose = (event, organization) => {
        handleOrganizationChange(organization);
        setAnchorElOrganization(null);
    };

    const formsLogout = () => {
        modalContext
            .showConfirmation({ Title: 'Are you sure you want to logout?' })
            .then((result) => {
                if (result) {
                    setAnchorEl(null);
                    cookies.remove('token', { domain: constants.CURRENT_DOMAIN_SUFFIX });
                    logout();
                }
            })
            .catch((err) => {});
    };

    const AllAppfn = () => {
        modalContext
            .showConfirmation({
                Title: 'Are you sure you want to exit from intelyForms?',
            })
            .then((result) => {
                if (result) {
                    setAnchorEl(null);
                    const currentUserDefaultOrganizationId = getCurrentOrganizationId();
                    localStorage.clear();
                    window.location.replace(
                        `${process.env.REACT_APP_INTELY_PLATFORM_EXTERNAL_HOST}/dashboard?currentOrganizationId=${currentUserDefaultOrganizationId}`,
                    );
                }
            })
            .catch((err) => {});
    };

    const ApiDOcs = () => {
        history.push('/intelyforms-api');
        setAnchorEl(null);
    };
    const handleadmin = () => {
        history.push('/admin');
        setAnchorEl(null);
    };

    const handleProfile = () => {
        setAnchorEl(null);
        localStorage.clear();
        window.location.replace(
            window.location.replace(process.env.REACT_APP_INTELY_PLATFORM_EXTERNAL_HOST + '/my-profile'), // "/my-profile"
        );
    };

    const gotosettings = () => {
        setAnchorEl(null);
        if ((props?.subscriptionPlan && props?.subsDetail?.nbrOfSmtp === true) || props?.data?.length > 0) {
            history.push('/setting/smtp-configuration');
        } else {
            history.push('/setting/login-history');
        }
    };
    const handleHome = () => {
        setAnchorEl(null);
        history.push('/');
    };

    useEffect(() => {
        if (props.fname && props.lname) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [props.fname, props.lname]);

    const exportCsv = (allColumns, allData) => {
        const columns = ['Login Type', 'IP Address', 'Date', 'Time'];

        getUserLoginActivity(0, 0)
            .then((res) => {
                if (res?.length > 0) {
                    const data = res.map((rowData) => [
                        rowData.others.loginType,
                        rowData.ip,
                        dayjs(rowData.activityDate).format('LL'),
                        dayjs(rowData.activityDate).format('LT'),
                    ]);
                    new _filefy.CsvBuilder('login History_' + dayjs().format('MMMM Do YYYY, h:mm:ss a'))

                        .setColumns(columns.map((columnDef) => columnDef))
                        .addRows(data)
                        .exportFile();
                }
            })
            .catch((err) => {});
    };

    const exportThirdPartyCsv = (allColumns, allData) => {
        const columns = ['Party Name', 'Activity', 'No. of Leads', 'Created Date', 'Form Name', 'IP Address'];

        getUserIntegrationActivity(0, 0).then((res) => {
            if (res?.length > 0) {
                const data = res.map((rowData) => [
                    rowData.others.thirdParty.charAt(0).toUpperCase() + rowData.others.thirdParty.slice(1),
                    rowData.activity.charAt(0).toUpperCase() + rowData.activity.slice(1),
                    rowData.leadCount ? rowData.leadCount : '0',
                    dayjs(rowData.activityDate).format('LLL'),
                    rowData.formTitle,
                    rowData.ip,
                ]);
                new _filefy.CsvBuilder('Integration History_' + dayjs().format('MMMM Do YYYY, h:mm:ss a'))
                    .setColumns(columns.map((columnDef) => columnDef.title))
                    .addRows(data)
                    .exportFile();
            }
        });
    };

    const isSearchBarVisible =
        history.location.pathname === '/' ||
        history.location.pathname === '/favorite' ||
        history.location.pathname === '/archives' ||
        history.location.pathname === '/trash' ||
        history.location.pathname.includes('/admin');

    const profileSearchBar =
        history.location.pathname.includes('/setting/login-history') ||
        history.location.pathname.includes('/setting/thirdparty-history');

    return (
        <Toolbar className={classes.divclass}>
            {props.loginSignUp ? (
                <img alt="" style={{ cursor: 'pointer' }} src={IntelyForm} />
            ) : (
                <img
                    alt=""
                    style={{ cursor: 'pointer' }}
                    src={IntelyForm}
                    onClick={() => (props.IsAdmin ? history.push('/') : history.push('/'))}
                />
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                {!mobileView && (
                    <>
                        {!history.location.pathname.includes('/setting/manage-subscription') &&
                        props?.subscriptionPlan === 'free forever' ? (
                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleUpgrade}
                                color="primary"
                                className={classes.btnclass}
                            >
                                Upgrade Plan
                            </Button>
                        ) : null}
                    </>
                )}

                {(isSearchBarVisible || profileSearchBar) && <SearchBar />}

                {history.location.pathname === '/setting/login-history' && (
                    <Tooltip title="Download CSV">
                        <IconButton onClick={() => exportCsv()} style={{ padding: 0, marginRight: 10 }}>
                            <CloudDownload />
                        </IconButton>
                    </Tooltip>
                )}

                {history.location.pathname === '/setting/thirdparty-history' && (
                    <Tooltip title="Download CSV">
                        <IconButton onClick={() => exportThirdPartyCsv()} style={{ padding: 0, marginRight: 10 }}>
                            <CloudDownload />
                        </IconButton>
                    </Tooltip>
                )}
                {!mobileView && (
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classes.btnclass1}
                        onClick={() => window.open('https://support.intely.io/')}
                    >
                        Contact Support
                    </Button>
                )}

                {!mobileView && (
                    <>
                        <Button
                            id="user-organization"
                            aria-controls={openOrganization ? 'user-organization' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openOrganization ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleOrganizationClick}
                            style={{
                                color: '#333333',
                                backgroundColor: '#FFFFFF',
                                boxShadow: 'none',
                                fontSize: 14,
                                fontWeight: 400,
                                minWidth: 150,
                                alignItems: 'right',
                                justifyContent: 'flex-end',
                                textTransform: 'none',
                                '& .MuiButton-root': {
                                    '& .MuiTypography-root': {
                                        color: '#333333',
                                        backgroundColor: '#FFFFFF',
                                        boxShadow: 'none',
                                        fontSize: 14,
                                        fontWeight: 400,
                                        minWidth: 150,
                                        textTransform: 'none !important',
                                        '&:hover': {
                                            color: '#333333',
                                            backgroundColor: '#FFFFFF',
                                            boxShadow: 'none',
                                            fontSize: 14,
                                            fontWeight: 400,
                                            minWidth: 150,
                                            textTransform: 'none !important',
                                        },
                                    },
                                },
                            }}
                            endIcon={<ArrowDropDownIcon style={{ color: '#C4C4C4', size: 14 }} />}
                        >
                            {defaultOrganization ? defaultOrganization.organizationName : 'Organization Name'}
                        </Button>
                        <StyledOrganizationMenu
                            id="user-organization-menu"
                            MenuListProps={{
                                'aria-labelledby': 'user-organization-menu-button',
                            }}
                            anchorEl={anchorElOrganization}
                            open={openOrganization}
                            onClose={(e) => handleOrganizationClose(e, defaultOrganization)}
                            TransitionComponent={Fade}
                        >
                            {organizationList &&
                                organizationList.map((organization, index) => {
                                    return (
                                        <MenuItem
                                            selected={
                                                organization.organizationId === defaultOrganization?.organizationId ||
                                                ''
                                            }
                                            key={'organization' + index}
                                            onClick={(event) => handleOrganizationClose(event, organization)}
                                        >
                                            {organization.organizationName}
                                        </MenuItem>
                                    );
                                })}
                        </StyledOrganizationMenu>
                    </>
                )}
                <div className={classes.menudiv}>
                    <ButtonBase onClick={SettingMenuClick}>
                        <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                            {profileimg ? (
                                <img alt="" src={profileimg} className={classes.usericon} />
                            ) : (
                                <Avatar
                                    style={{
                                        width: '36px',
                                        height: '36px',
                                        fontSize: '15px',
                                        color: '#6198FF',
                                        border: '1px solid #6198FF',
                                        borderRadius: '50%',
                                        background: '#E0E0E0',
                                    }}
                                >
                                    {loading === true ? (
                                        <CircularProgress size={10} />
                                    ) : (
                                        <>
                                            {avatarName(
                                                `${props.fname ? props.fname : null}${' '}${
                                                    props.lname ? props.lname : null
                                                }`,
                                            )}
                                        </>
                                    )}
                                </Avatar>
                            )}
                        </StyledBadge>
                        {anchorEl === null ? (
                            <ArrowDropDownIcon className={classes.arrowicon} />
                        ) : (
                            <ArrowDropUpIcon className={classes.arrowicon1} />
                        )}
                    </ButtonBase>

                    <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        className={
                            history.location.pathname.includes('builder') ? classes.stylemenu1 : classes.stylemenu
                        }
                        onClose={SettingMenuClose}
                    >
                        <Collapse in={anchorEl} timeout={1000}>
                            <ListItem className={classes.menulistitem} onClick={handleProfile}>
                                <Myuser fontSize="small" className={classes.menuIcon} />
                                <ListItemText>
                                    {loading === true ? (
                                        <Typography className={classes.menulabletext1}>
                                            <LinearProgress />
                                        </Typography>
                                    ) : (
                                        <Typography className={classes.menulabletext1}>
                                            {`${props.fname}${' '}${props.lname}`}
                                        </Typography>
                                    )}
                                </ListItemText>
                            </ListItem>
                            {props.IsAdmin ? (
                                <ListItem className={classes.menulistitem} onClick={handleHome}>
                                    <HomeIcon fontSize="small" className={classes.menuIcon1} />
                                    <ListItemText>
                                        <Typography className={classes.menulabletext}>Home</Typography>
                                    </ListItemText>
                                </ListItem>
                            ) : (
                                <ListItem className={classes.menulistitem} onClick={handleadmin}>
                                    <AdminSettings fontSize="small" className={classes.menuIcon} />
                                    <ListItemText>
                                        <Typography className={classes.menulabletext}>Admin</Typography>
                                    </ListItemText>
                                </ListItem>
                            )}
                            {history.location.pathname.includes('setting') &&
                            !history.location.pathname.includes('settings') ? null : (
                                <ListItem className={classes.menulistitem} onClick={() => gotosettings()}>
                                    <FormSettings fontSize="small" className={classes.menuIcon} />
                                    <ListItemText>
                                        <Typography className={classes.menulabletext}>Settings</Typography>
                                    </ListItemText>
                                </ListItem>
                            )}

                            {mobileView && (
                                <>
                                    {!history.location.pathname.includes('/setting/manage-subscription') &&
                                    props.subscriptionPlan === 'free forever' ? (
                                        <ListItem className={classes.menulistitem} onClick={handleUpgrade}>
                                            <LocalAtmIcon fontSize="small" className={classes.menuIcon} />

                                            <ListItemText>
                                                <Typography className={classes.menulabletext}>Upgrade Plan</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    ) : null}
                                    <ListItem
                                        className={classes.menulistitem}
                                        onClick={() => window.open('https://support.intely.io/')}
                                    >
                                        <ContactSupportIcon fontSize="small" className={classes.menuIcon} />
                                        <ListItemText>
                                            <Typography className={classes.menulabletext}>Contact Support</Typography>
                                        </ListItemText>
                                    </ListItem>
                                </>
                            )}
                            <ListItem className={classes.menulistitem} onClick={AllAppfn}>
                                <AllApps fontSize="small" className={classes.menuIcon} />

                                <ListItemText>
                                    <Typography className={classes.menulabletext}>Switch App</Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem className={classes.menulistitem} onClick={ApiDOcs}>
                                <ArticleIcon fontSize="small" className={classes.menuIcon} />

                                <ListItemText>
                                    <Typography className={classes.menulabletext}>API Docs</Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem className={classes.menulistitem} onClick={formsLogout}>
                                <Dropdownlogout fontSize="small" className={classes.menuIcon} />

                                <ListItemText>
                                    <Typography className={classes.menulabletext}>Logout</Typography>
                                </ListItemText>
                            </ListItem>
                        </Collapse>
                    </StyledMenu>
                    {/* )} */}
                </div>
            </Box>
        </Toolbar>
    );
};
const mapStateToProps = (state) => {
    return {
        subscriptionPlan: state?.userProfile.user?.subscriptionPlan,
        integration: state.userProfile.user?.subsDetail?.integration,
        fname: state.userProfile.user?.firstName,
        lname: state.userProfile.user?.lastName,
        subsDetail: state.userProfile.user?.subsDetail,
        data: state?.workspace?.data,
    };
};
export default connect(mapStateToProps)(IFToolbar);
