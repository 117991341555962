import axios from '../axios';
import { Cookies } from 'react-cookie';
import store from '../redux/store';

import HttpStatusCodes from '../classes/HttpStatusCodes';

const cookies = new Cookies();

/**
 * Helper function to store items in local storage
 * @param {String} key
 * @param {String} value
 * @returns {void}
 */

const setLocalStorageCacheItem = (key, value) => {
    if (typeof value !== 'string') {
        value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
};

/**
 *
 * @param {String} key
 * @param {String} urlQuery
 * @returns {Object} Returns object with provided key and value
 */
export const parseURLQueries = (urlQuery, key) => {
    const params = urlQuery.split('&');
    const paramsMap = {};
    params.forEach((p) => {
        const [paramKey, value] = p.split('=');
        if (key === paramKey) {
            paramsMap[paramKey] = decodeURIComponent(value);
        }
    });
    return paramsMap;
};

/**
 * Process the request response object
 * @param {Object} response
 * @param {Function|null} callback Success callback
 * @returns {any}
 */
const processRequestResponse = (response, callback = null) => {
    if (response?.status === HttpStatusCodes.SUCCESS) {
        if (callback) {
            callback(response.data);
        }

        return response.data;
    } else {
        throw response.errors;
    }
};

/**
 * Validate the authenticated user session token
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getUserLogin = async () => {
    return axios
        .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST}/currentUser/validate`)
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 * Get user data from back-end.
 * @param {String} userId
 * @param {String} userToken
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getUserData = async (userId, userToken) => {
    return axios
        .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST}/user/${userId}`, {
            headers: {
                Authorization: userToken,
            },
        })
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 * Get organization data from back-end
 * @param {String} organizationId
 * @param {String} userToken
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOrganizationData = async (organizationId) => {
    return axios
        .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST}/organization/${organizationId}`)
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 * Gets current user's organizations
 * @param {String} userId
 * @param {String} userToken
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getUserOrganizationData = async (userId) => {
    return axios
        .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST}/user/${userId}/organizations`)
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 * Get the authenticated user session token.
 * @returns {String}
 */
export const getCurrentUserToken = () => {
    return cookies.get('token');
};

/**
 * Get the authenticated user data.
 * @returns {Promise<AxiosResponse<*> | boolean>}
 */
export const getCurrentUserData = async () => {
    return getUserLogin()
        .then(async (data) => {
            return data;
        })
        .catch(() => {
            return false;
        });
};

/**
 * Get current authenticated user organization ID
 * @param {Object} userData
 * @returns {String}
 */
export const getUserCurrentOrganizationId = (userData) =>
    // TODO need to adjust organizations list to use current organization from banner
    Array.isArray(userData.organizations) && userData.organizations.length > 0 ? userData.organizations[0] : '';

/**
 * Get current authenticated user organization ID
 * @param {String} currentUserToken
 * @param {Object} userData
 * @returns {Promise<void>}
 */
export const getUserCurrentOrganizationData = async () => {
    let organizationData;
    try {
        organizationData = await getOrganizationData(getCurrentOrganizationId());
    } catch (error) {
        // TODO log exception
    }

    return organizationData;
};

/**
 *
 * @returns {String}
 */
export const getCurrentUserId = () => {
    return store.getState().userdata.data.userId;
};

/**
 * @returns {String}
 */
export const getCurrentUserOrganizationId = () => {
    return store.getState().currentOrganization.organizationId;
};

/**
 * Returns current selected Organization Id stored in local storage cache
 * @returns {String}
 */
export const getCurrentOrganizationId = () => {
    const organizationId = localStorage.getItem('currentOrganizationId');
    return organizationId;
};

/**
 * Wipe out the user session and go to the platform login page.
 * process
 */
export const logout = () => {
    localStorage.clear();
    window.location.replace(`${process.env.REACT_APP_INTELY_PLATFORM_EXTERNAL_HOST}/logout`);
};

/**
 * Stores default organization and customer Id in local storage.
 * @param {{String}} organizationId
 * @param {{String}} organizationStripeCustomerId
 * @returns {void}
 */
export const setOrganizationData = ({ organizationId, organizationStripeCustomerId }) => {
    setLocalStorageCacheItem('currentOrganizationId', organizationId);
    setLocalStorageCacheItem('stripeCustomerId', organizationStripeCustomerId);
};

/**
 * Set Banner selected organization
 * @param {object} data selected organization
 * @returns {void}
 */
export const setCurrentOrganization = (data) => {
    setLocalStorageCacheItem('currentOrganizationId', data);
    store.dispatch({
        type: 'CURRENT_ORGANIZATION',
        payload: data,
    });
};

/**
 *  Stores user token in local storage
 * @param {String} token
 * @returns {void}
 */
export const setUserToken = (token) => {
    setLocalStorageCacheItem('token', token);
};
