/* eslint-disable */
import Workspace from 'components/login/workspace';

import PaymentPackage from 'containers/PaymentPackage';
import PaymentSuccess from 'containers/PaymentSuccess';
import StripePaymentSuccess from 'containers/StripePaymentSuccess';
import React, { lazy, useState } from 'react';
import { useHistory } from 'react-router';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import IFToolbar from 'Utils/IFToolbar';
import IFToolbarLoginSignUp from 'Utils/IFToolbarLoginSignUp';
import ProtectedRoutes from './ProtectedRoutes';

const CreateNewForm = lazy(() => import('containers/Home'));
const AdminForm = lazy(() => import('containers/HomeAdmin'));
const EditProfile = lazy(() => import('containers/EditProfile'));
const BuilderContainer = lazy(() => import('containers/Builder'));
const AdminBuilder = lazy(() => import('containers/AdminBuilder'));
const PublicForm = lazy(() => import('containers/myform'));
const FormSubmission = lazy(() => import('containers/FormSubmission'));
const ApiDocumentation = lazy(() => import('containers/ApiDocumentation'));
const App = () => {
    let history = useHistory();
    const [IsAdmin, setIsAdmin] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [profileimg, setProfileImg] = useState();
    const [upgradeflag, setupgradeflag] = useState('Settings');
    const [pagechange, setpagechange] = useState('paymentpackage');
    const [subId, setsubId] = useState('');
    const [loadingBtn, setloadingBtn] = useState(false);
    return (
        <>
            <Router>
                <Switch>
                    <Route exact path="/paymentsuccessfull" render={() => <PaymentSuccess />} />
                    <Route exact path="/stripe-payment-successfull/:id" render={() => <StripePaymentSuccess />} />

                    <Route
                        exact
                        path="/workspace/registration"
                        render={(props) => (
                            <>
                                <Workspace {...props} />
                            </>
                        )}
                    />
                    <Route exact path="/myform/:id" render={(props) => <PublicForm {...props} />} />

                    <Route
                        exact
                        path="/subscription-plans"
                        render={(props) => (
                            <>
                                <IFToolbarLoginSignUp />
                                <PaymentPackage
                                    {...props}
                                    setloadingBtn1={setloadingBtn}
                                    loadingBtn1={loadingBtn}
                                    setpagechange={setpagechange}
                                    pagechange={pagechange}
                                />
                            </>
                        )}
                    />

                    <ProtectedRoutes
                        path="/admin/builder/"
                        render={(props) => (
                            <>
                                <IFToolbar
                                    IsAdmin={true}
                                    setIsAdmin={setIsAdmin}
                                    profileimg={profileimg}
                                    setProfileImg={setProfileImg}
                                />
                                <AdminBuilder
                                    {...props}
                                    IsAdmin={true}
                                    setIsAdmin={setIsAdmin}
                                    profileimg={profileimg}
                                    setProfileImg={setProfileImg}
                                />
                            </>
                        )}
                    ></ProtectedRoutes>
                    <ProtectedRoutes
                        path="/admin"
                        render={(props) => (
                            <>
                                <IFToolbar
                                    IsAdmin={true}
                                    setIsAdmin={setIsAdmin}
                                    profileimg={profileimg}
                                    setProfileImg={setProfileImg}
                                />
                                <AdminForm
                                    IsAdmin={true}
                                    setIsAdmin={setIsAdmin}
                                    profileimg={profileimg}
                                    setProfileImg={setProfileImg}
                                    {...props}
                                />
                            </>
                        )}
                    />

                    <ProtectedRoutes
                        path="/setting"
                        render={(props) => (
                            <>
                                <IFToolbar
                                    setsubId={setsubId}
                                    subId={subId}
                                    IsAdmin={false}
                                    setIsAdmin={setIsAdmin}
                                    profileimg={profileimg}
                                    setProfileImg={setProfileImg}
                                    {...props}
                                />

                                <EditProfile
                                    setsubId={setsubId}
                                    subId={subId}
                                    IsAdmin={false}
                                    setIsAdmin={setIsAdmin}
                                    profileimg={profileimg}
                                    setProfileImg={setProfileImg}
                                    setupgradeflag={setupgradeflag}
                                    upgradeflag={upgradeflag}
                                    {...props}
                                />
                            </>
                        )}
                    />

                    <ProtectedRoutes
                        path="/builder/"
                        render={(props) => (
                            <>
                                {!window.location.pathname.includes(
                                    '/builder/forms/connect-successfull/auth/callback',
                                ) && (
                                    <IFToolbar
                                        setsubId={setsubId}
                                        subId={subId}
                                        IsAdmin={false}
                                        setIsAdmin={setIsAdmin}
                                        profileimg={profileimg}
                                        setProfileImg={setProfileImg}
                                    />
                                )}
                                <BuilderContainer
                                    {...props}
                                    setsubId={setsubId}
                                    subId={subId}
                                    IsAdmin={false}
                                    setIsAdmin={setIsAdmin}
                                    profileimg={profileimg}
                                    setProfileImg={setProfileImg}
                                />
                            </>
                        )}
                    />

                    <ProtectedRoutes
                        exact
                        path="/form-submission/:id"
                        render={(props) => (
                            <>
                                <IFToolbar
                                    IsAdmin={true}
                                    setIsAdmin={setIsAdmin}
                                    profileimg={profileimg}
                                    setProfileImg={setProfileImg}
                                />
                                <FormSubmission
                                    anchorEl={anchorEl}
                                    setAnchorEl={setAnchorEl}
                                    profileimg={profileimg}
                                    setProfileImg={setProfileImg}
                                    history={history}
                                    {...props}
                                />
                            </>
                        )}
                    />

                    <ProtectedRoutes exact path="/myform/:id" component={PublicForm} />

                    <Route
                        path="/intelyforms-api"
                        render={(props) => (
                            <>
                                <ApiDocumentation {...props} />
                            </>
                        )}
                    />

                    <ProtectedRoutes
                        path="/"
                        render={(props) => (
                            <>
                                <IFToolbar
                                    IsAdmin={false}
                                    setIsAdmin={setIsAdmin}
                                    profileimg={profileimg}
                                    setProfileImg={setProfileImg}
                                />
                                <CreateNewForm
                                    IsAdmin={false}
                                    setIsAdmin={setIsAdmin}
                                    profileimg={profileimg}
                                    setProfileImg={setProfileImg}
                                    {...props}
                                />
                            </>
                        )}
                    />
                </Switch>
            </Router>
        </>
    );
};

export default App;
