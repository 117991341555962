import PropTypes from 'prop-types';

import {
    Wrapper,
    ErrorImage,
    HeaderText,
    SubText,
    Button,
    BannerFooter,
    BannerFooterGradient,
    BannerFooterGradient404,
} from './styles';

import icon404 from '../../assets/images/404Icon.svg';
import iconOops from '../../assets/images/oopsIcon.svg';
import bannerFooter from '../../assets/images/bannerFooter.svg';
import bannerFooterGradient from '../../assets/images/bannerFooterGradient.svg';

const ErrorPage = (props) => {
    const { errorCode } = props;
    const headerText = errorCode === 404 ? "Looks like you're lost" : 'Something went wrong';
    const subText =
        errorCode === 404 ? "Sorry, we can't find the page you're looking for" : "That wasn't supposed to happen";

    const returnHome = () => {
        const currentUserDefaultOrganizationId = localStorage.getItem('currentOrganizationId');
        localStorage.removeItem('currentOrganizationId');
        window.location.replace(
            `${process.env.REACT_APP_INTELY_PLATFORM_EXTERNAL_HOST}/apps?currentOrganizationId=${currentUserDefaultOrganizationId}`,
        );
    };

    return (
        <Wrapper>
            <ErrorImage src={errorCode === 404 ? icon404 : iconOops} />
            <HeaderText>{headerText}</HeaderText>
            <SubText>{subText}</SubText>
            <Button onClick={errorCode === 404 ? () => props.history.replace('/') : returnHome}>Back to Home</Button>
            <BannerFooter src={bannerFooter} />
            {errorCode === 404 ? (
                <BannerFooterGradient404 src={bannerFooterGradient} />
            ) : (
                <BannerFooterGradient src={bannerFooterGradient} />
            )}
        </Wrapper>
    );
};

ErrorPage.propTypes = {
    errorCode: PropTypes.number,
};

export default ErrorPage;
