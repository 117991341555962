import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const add = (props) => {
    return (
        <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.0714 0H1.92857C0.84375 0 0 0.883929 0 1.92857V16.0714C0 17.1562 0.84375 18 1.92857 18H16.0714C17.1161 18 18 17.1562 18 16.0714V1.92857C18 0.883929 17.1161 0 16.0714 0ZM15.8304 16.0714H2.16964C2.00893 16.0714 1.92857 15.9911 1.92857 15.8304V2.16964C1.92857 2.04911 2.00893 1.92857 2.16964 1.92857H15.8304C15.9509 1.92857 16.0714 2.04911 16.0714 2.16964V15.8304C16.0714 15.9911 15.9509 16.0714 15.8304 16.0714ZM13.8214 4.33929C13.8214 4.09821 13.5804 3.85714 13.3393 3.85714H12.375C12.0938 3.85714 11.8929 4.09821 11.8929 4.33929V8.03571H6.10714V4.33929C6.10714 4.09821 5.86607 3.85714 5.625 3.85714H4.66071C4.37946 3.85714 4.17857 4.09821 4.17857 4.33929V13.6607C4.17857 13.942 4.37946 14.1429 4.66071 14.1429H5.625C5.86607 14.1429 6.10714 13.942 6.10714 13.6607V9.96429H11.8929V13.6607C11.8929 13.942 12.0938 14.1429 12.375 14.1429H13.3393C13.5804 14.1429 13.8214 13.942 13.8214 13.6607V4.33929Z"
                fill="#7D8995"
            />
        </SvgIcon>
    );
};
export default add;
